import './index.css'
import { motion } from 'framer-motion'
import { SlideFromBottomAuto } from '../../animation'

function NoAccount({ close, buyFunc, bindFunc, manualFunc }) {
    return <div className='app-page-container layout'>
        <motion.div className='app-game-noaccount-container' {...SlideFromBottomAuto}>
            <div className='app-game-noaccount-header'>
                <p className='app-game-noaccount-title'>该游戏需要自备账号才能体验</p>
                <i className='app-game-noaccount-close' onClick={close}></i>
            </div>
            {/* <div className='app-game-noaccount-buy-container'>
                <i className='app-game-noaccount-icon buy'></i>
                <div className='app-game-noaccount-txt-wrapper'>
                    <p className='app-game-noaccount-txt-title'>买账号送时长</p>
                    <p className='app-game-noaccount-txt-desc'>永久账号，秒购秒玩</p>
                </div>
                <button className='app-game-noaccount-btn buy' onClick={buyFunc}>去购买</button>
            </div> */}
            <div className='app-game-noaccount-bind-container'>
                <i className='app-game-noaccount-icon bind'></i>
                <div className='app-game-noaccount-txt-wrapper'>
                    <p className='app-game-noaccount-txt-title'>我有账号</p>
                    <p className='app-game-noaccount-txt-desc'>一次绑定，自动登录</p>
                </div>
                <button className='app-game-noaccount-btn bind' onClick={bindFunc}>去绑定</button>
            </div>
            <p className='app-game-noaccount-manual'><span onClick={manualFunc}>手动登录<span className='goTo'>&gt;</span></span></p>
        </motion.div>
    </div>
}

export default NoAccount