import './index.css'
import { useEffect, useState, useContext, useRef } from 'react'
import Dialog from '../../components/dialog'
import { startGame } from '../game/service'
import AppContext from '../../common/context'
import { compareTime, getTimeForSec } from '../../common/util'
import PubSub from 'pubsub-js'
import { EventTip } from '../../common/event'
import CustomImage from '../../components/image'

const RefreshInterval = 5
const getSpeed = n => {
    n < 0 && (n = -n)
    return n * 100 + '%'
}
const getWaitTime = (n, i) => {
    return `${i === 1 ? '小于' : '大于'}${(n || 1) * 5}分钟`
}

function Queue({ queueInfo, close, gameId, gameImg, atype, account }) {
    const { sysConfig, setGame, togglePay, setPayIndex, user, toggleWaiting, setWaitingInfo, toggleLvDetail, toggleLvDetailFromVertical, toggleSignIn, toggleSignInFromVertical } = useContext(AppContext)
    const [showCancelDialog, toggleCancelDialog] = useState(false)
    const [showChangeDialog, toggleChangeDialog] = useState(false)
    const [showChargeDialog, toggleChargeDialog] = useState(false)
    const [showHighWayDialog, toggleHighWayDialog] = useState(false)
    const [refreshHelper, setRefreshHelper] = useState(0)
    const [currentType, setCurrentType] = useState(-1)
    const [isPeak, togglePeak] = useState(queueInfo.isPeak)
    const [queueNum, setQueueNum] = useState(queueInfo.queueNum)
    const [queueSpeed, setQueueSpeed] = useState(queueInfo.queueSpeed)
    const [waitTime, setWaitTime] = useState('00:00:00')
    const refreshTimer = useRef(null)
    const originalChannel = useRef(queueInfo.isPeak)

    useEffect(() => {
        let time = 0
        const refresh = () => {
            ++time
            setWaitTime(getTimeForSec(time))
            if (time % RefreshInterval === 0) {
                setRefreshHelper(h => h + 1)
            }
            refreshTimer.current = setTimeout(refresh, 1000)
        }

        refreshTimer.current = setTimeout(refresh, 1000)

        return () => {
            clearTimeout(refreshTimer.current)
        }
    }, [])

    useEffect(() => {
        if (!refreshHelper) {
            return
        }
        startGame(gameId, originalChannel.current, atype, account).then(data => {
            const { appChannel, ctoken, pkgName, playTime, protoData, utoken, isPeak: channel, tempTime, accountData } = data
            setGame({
                gameId,
                appChannel,
                ctoken,
                pkgName,
                playTime,
                protoData,
                utoken,
                tempTime,
                channel,
                accountData
            })
            setWaitingInfo({
                gameImg,
                channel
            })
            toggleWaiting(true)
            close()
        }).catch(e => {
            if (e.code === 201) {
                const { isPeak: newIsPeak, queueNum: newQueueNum, queueSpeed: newQueueSpeed } = e.data
                togglePeak(newIsPeak)
                originalChannel.current = newIsPeak
                setQueueNum(newQueueNum)
                setQueueSpeed(newQueueSpeed)
            } else if (e.code === 202) {
                toggleChargeDialog(true)
            } else {
                PubSub.publish(EventTip, { msg: e.message })
            }
        })
    }, [close, toggleWaiting, setGame, gameId, refreshHelper, gameImg, setWaitingInfo, account])

    useEffect(() => {
        const { freeTime } = sysConfig
        if (Array.isArray(freeTime) && freeTime.length > 1) {
            const [start, end] = freeTime
            setCurrentType(compareTime(start, end) ? 0 : 1)
        }
    }, [sysConfig, refreshHelper])

    const openCancelDialog = () => {
        toggleCancelDialog(true)
    }

    const closeCancelDialog = () => {
        toggleCancelDialog(false)
    }

    const openChangeDialog = () => {
        toggleChangeDialog(true)
    }

    const closeChangeDialog = () => {
        toggleChangeDialog(false)
    }

    const closeChargeDialog = () => {
        toggleChargeDialog(false)
    }

    const change = () => {
        closeChangeDialog()
        originalChannel.current = 1
        setRefreshHelper(-1)
    }

    const goToPay = index => {
        setPayIndex(index)
        togglePay(true)
    }

    const goToBuyTime = () => {
        goToPay(1)
        closeChargeDialog()
    }

    const goToLvDetail = () => {
        toggleLvDetailFromVertical(false)
        toggleLvDetail(true)
    }

    const closeHighWayDialog = () => {
        toggleHighWayDialog(false)
    }

    const goToSignIn = () => {
        toggleSignInFromVertical(false)
        toggleSignIn(true)
        closeHighWayDialog()
    }

    const goToBuyVip = () => {
        goToPay(0)
        closeHighWayDialog()
    }

    return (
        <>
            <div className='app-page-container'>
                <div className='app-queue-page'>
                    <i className='app-queue-quit' onClick={openCancelDialog}></i>
                    <div className='app-queue-main'>
                        <div className='app-queue-main-top'>
                            <p className='app-queue-top-desc'>
                                {currentType === 0 && <span className='app-queue-top-desc-type'>普通时段</span>}
                                {currentType === 1 && <span className='app-queue-top-desc-type peak'>高峰时段</span>}
                                {isPeak === 1 ? '高峰' : '免费'}
                                通道排队中
                            </p>
                            <p className='app-queue-top-desc-wait'>预估等待{getWaitTime(queueNum, isPeak)}</p>
                        </div>
                        <div className='app-queue-main-body'>
                            <div className='app-queue-main-body-content-wrapper'>
                                <CustomImage className='app-queue-main-body-content-bg' src={gameImg}></CustomImage>
                                <div className='app-queue-main-body-content'>
                                    <div className='app-queue-main-user-level-wrapper'>
                                        <span className='app-queue-main-user-level' onClick={goToLvDetail}>LV{user.lv}</span>
                                    </div>
                                    <p className='app-queue-main-speed-tip'>排队速度{queueSpeed > 0 ? '超过' : '低于'}<span className='app-queue-main-speed-tip-value'>{getSpeed(queueSpeed)}</span>的用户</p>
                                    <div className='app-queue-btn-wrapper'>
                                        <button className='app-queue-btn' onClick={() => toggleHighWayDialog(true)}>获取高速</button>
                                    </div>
                                    <p className='app-queue-tip-vip'>VIP享排队优先权</p>
                                    <p className='app-queue-time-tip'>成长等级越高排队速度越快</p>
                                    <p className='app-queue-time-tip'>{waitTime}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='app-queue-bottom-tip-wrapper'>
                        <p className='app-queue-bottom-tip'>排队期间不消耗游戏时长</p>
                    </div>
                    {
                        isPeak === 0 && <div className='app-queue-change-wrapper'>
                            <button className='app-queue-change-btn' onClick={openChangeDialog}>高峰通道</button>
                        </div>
                    }
                </div>
            </div>
            {
                showCancelDialog && <Dialog title={`是否确认退出排队`} cancelFunc={closeCancelDialog} okFunc={close}></Dialog>
            }
            {
                showChangeDialog && <Dialog title={`当前排队时间较久，高峰通道秒进`} desc='每1小时高峰游戏+60成长值' cancelFunc={closeChangeDialog} okFunc={change}></Dialog>
            }
            {
                showChargeDialog && <Dialog title={`高峰有效时长不足，是否补充时长？`} okTxt='补充时长' cancelFunc={closeChargeDialog} okFunc={goToBuyTime}></Dialog>
            }
            {
                showHighWayDialog && <div className='app-page-container dialog'>
                    <div className='app-queue-highway-dialog'>
                        <i className='app-queue-highway-close' onClick={closeHighWayDialog}></i>
                        <p className='app-queue-highway-title'>如何提速</p>
                        <div className='app-queue-highway-block'>
                            <i className='app-queue-highway-icon packet'></i>
                            <div className='app-queue-highway-content'>
                                <p className='app-queue-highway-block-title'>每日红包</p>
                                <p className='app-queue-highway-block-desc'>红包领时长秒进游戏</p>
                            </div>
                            <button className='app-queue-highway-btn' onClick={goToSignIn}>领取使用</button>
                        </div>
                        <div className='app-queue-highway-block recharge'>
                            <i className='app-queue-highway-icon recharge'></i>
                            <div className='app-queue-highway-content'>
                                <p className='app-queue-highway-block-title'>充值套餐</p>
                                <p className='app-queue-highway-block-desc'>充值时长或会员，高峰时长秒进游戏</p>
                            </div>
                            <button className='app-queue-highway-btn recharge' onClick={goToBuyVip}>去购买</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Queue