import './index.css'
import PubSub from 'pubsub-js'
import { EventUserInvalid, EventTip, EventType } from '../../common/event'
import { useEffect, useState, useRef } from 'react'
import { applyGame } from './service'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'
import GroupDialog from '../../components/groupDialog'

function Apply({ close }) {
    const [name, setName] = useState('')
    const [platform, setPlatform] = useState('')
    const [showGroupDialog, toggleGroupDialog] = useState(false)
    const submitFlag = useRef(false)

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [close])

    const inputName = e => {
        const { value } = e.target
        setName(value)
    }

    const clearName = () => {
        setName('')
    }

    const inputPlatform = e => {
        const { value } = e.target
        setPlatform(value)
    }

    const clearPlatform = () => {
        setPlatform('')
    }

    const submit = () => {
        const gameName = (name || '').trim()
        const gamePlatform = (platform || '').trim()
        if (!gameName || !gamePlatform || submitFlag.current) {
            return
        }
        submitFlag.current = true
        applyGame(gameName, gamePlatform).then(() => {
            PubSub.publish(EventTip, { msg: '提交成功', type: EventType.Success })
            clearName()
            clearPlatform()
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const closeGroupDialog = () => {
        toggleGroupDialog(false)
    }

    const openGroupDialog = () => {
        toggleGroupDialog(true)
    }

    return (
        <motion.div className='app-page-container pt44' {...SlideFromRight}>
            <div className='app-page-header'>
                <h1 className='app-page-header-title'>游戏申请</h1>
                <i className='app-page-header-back' onClick={close}></i>
                <div className='app-page-header-group' onClick={openGroupDialog}>加入内测群</div>
            </div>
            <div className='app-apply-page-content'>
                <p className='app-apply-form-label'>游戏名称</p>
                <div className='app-apply-input-wrapper'>
                    <input className='app-apply-input' value={name} onChange={inputName} placeholder='请输入您申请上架的游戏名称'></input>
                    {
                        !!(name || '').trim() && <i className='app-apply-input-cancel' onClick={clearName}></i>
                    }
                </div>
                <p className='app-apply-form-label platform'>游戏平台</p>
                <div className='app-apply-input-wrapper'>
                    <input className='app-apply-input' value={platform} onChange={inputPlatform} placeholder='请输入游戏所属平台'></input>
                    {
                        !!(platform || '').trim() && <i className='app-apply-input-cancel' onClick={clearPlatform}></i>
                    }
                </div>
                <button className={!!(name || '').trim() && !!(platform || '').trim() ? 'app-apply-btn' : 'app-apply-btn disabled'} onClick={submit}>提交</button>
            </div>
            {
                showGroupDialog && <GroupDialog close={closeGroupDialog}></GroupDialog>
            }
        </motion.div>
    )
}

export default Apply