import './index.css'
import { useState, useEffect, useRef, useContext } from 'react'
import PubSub from 'pubsub-js'
import { EventTip, EventType } from '../../common/event'
import { sendSMSCode } from './service'
// import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { SlideFromBottom } from '../../animation'
import AppContext from '../../common/context'
import { getWechatLoginUrl, IsWechatBrowser } from '../../config'

const PhoneReg = /^1\d{0,10}$/
const ValidPhoneReg = /^1\d{10}$/

function Login() {
    const { toggleLoginPage, setLoginPhone, toggleSmsPage, setAgreementType, toggleAgreement, bindPhone, toggleBindPhone, showLoginPage } = useContext(AppContext)
    const [agree, setAgree] = useState(false)
    const [showAgreeTip, toggleAgreeTip] = useState(false)
    const [showClean, toggleClean] = useState(false)
    const [valid, toggleValid] = useState(false)
    const [inputPhone, setInputPhone] = useState('')
    const submitFlag = useRef(false)
    // const navigate = useNavigate()

    useEffect(() => {
        agree && toggleAgreeTip(false)
    }, [agree])

    useEffect(() => {
        const value = (inputPhone || '').replace(/\s/g, '')
        toggleClean(!!value)
        toggleValid(ValidPhoneReg.test(value))
    }, [inputPhone])

    const input = e => {
        const { value } = e.target
        if (!value) {
            return setInputPhone(value)
        }
        let trimValue = value.replace(/\s/g, '')
        if (!PhoneReg.test(trimValue)) {
            return
        }
        const isDel = value.length < inputPhone.length
        if (trimValue.length >= 7) {
            setInputPhone(`${trimValue.slice(0, 3)} ${trimValue.slice(3, 7)}${trimValue.length === 7 && isDel ? '' : ' '}${trimValue.slice(7)}`)
        } else if (trimValue.length >= 3) {
            setInputPhone(`${trimValue.slice(0, 3)}${trimValue.length === 3 && isDel ? '' : ' '}${trimValue.slice(3)}`)
        } else {
            setInputPhone(trimValue)
        }
    }

    const cleanPhone = () => {
        setInputPhone('')
    }

    const submit = () => {
        if (!valid || submitFlag.current) {
            return
        }
        if (!agree && !bindPhone) {
            return toggleAgreeTip(true)
        }
        submitFlag.current = true
        const phone = inputPhone.replace(/\s/g, '')
        sendSMSCode(phone).then(() => {
            submitFlag.current = false
            PubSub.publish(EventTip, { msg: '发送成功', type: EventType.Success })
            // navigate(`/sms/${phone}`)
            setLoginPhone(phone)
            toggleSmsPage(true)
        }).catch(e => {
            submitFlag.current = false
            PubSub.publish(EventTip, { msg: e.message })
        })
    }

    const close = () => {
        toggleLoginPage(false)
    }

    const goToServiceAgreement = e => {
        e.stopPropagation()
        setAgreementType(1)
        toggleAgreement(true)
    }

    const goToPrivacyAgreement = e => {
        e.stopPropagation()
        setAgreementType(2)
        toggleAgreement(true)
    }

    const goToWechatLogin = () => {
        window.location.href = getWechatLoginUrl(window.location.href, 'wl')
    }

    const motionCallback = () => {
        !showLoginPage && toggleBindPhone(false)
    }

    return (
        <motion.div className='app-page-container zIndex3' {...SlideFromBottom} onAnimationComplete={motionCallback}>
            <div className='app-page-window'>
                <div className='app-whole-page app-login-wrapper'>
                    <div className='app-login-header'>
                        <i className='app-login-header-close' onClick={close}></i>
                    </div>
                    <div className='app-login-main-content'>
                        <p className='app-login-phone-title'>
                            <i className='app-login-phone-icon'></i>
                            手机号{bindPhone ? '绑定' : '登录'}
                        </p>
                        <p className='app-login-phone-tip'>{bindPhone ? '绑定后享受更好的使用体验' : '未注册的手机验证后自动创建账户'}</p>
                        <div className='app-login-phone-input-wrapper'>
                            <input className='app-login-phone-input' placeholder='请输入手机号' value={inputPhone} onChange={input}></input>
                            <i className={showClean ? 'app-login-input-clean' : 'app-login-input-clean hidden'} onClick={cleanPhone}></i>
                        </div>
                        <div className='app-login-btn-wrapper'>
                            <button className={valid ? 'app-login-btn' : 'app-login-btn disabled'} onClick={submit}>
                                <i className='app-login-btn-icon'></i>
                            </button>
                        </div>
                        {
                            bindPhone
                                ? <>
                                    <div className='app-login-bind-tip'>为了保证您的账户资金安全，请绑定手机号</div>
                                    <div className='app-login-bind-tip'>若你的账号已绑定手机号，需原手机号验证身份，提升账户安全</div>
                                </>
                                : <div className='app-login-agreement' onClick={() => setAgree(!agree)}>
                                    <div className={agree ? 'app-login-agreement-checkbox agree' : 'app-login-agreement-checkbox'}>
                                        {
                                            showAgreeTip && <div className='app-login-agreement-tip'>
                                                <p className='app-login-agreement-tip-content'>👀请先同意隐私政策及用户协议</p>
                                            </div>
                                        }
                                    </div>
                                    登录即表示同意
                                    <span className='app-login-agreement-item' onClick={goToServiceAgreement}>《用户使用协议》</span>
                                    和
                                    <span className='app-login-agreement-item' onClick={goToPrivacyAgreement}>《隐私政策》</span>
                                </div>
                        }
                    </div>
                    {
                        IsWechatBrowser && !bindPhone && <div className='app-third-login-wrapper'>
                            <div className='app-third-login-title'>其他登录方式</div>
                            <ul className='app-third-login-ul'>
                                <li className='app-third-login-wechat' onClick={goToWechatLogin}></li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </motion.div>
    )
}

export default Login