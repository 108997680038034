import Rank from '../../components/rank'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'

function GameRank({ toggleRankState }) {

    const goBack = () => {
        toggleRankState(false)
    }

    return (
        <motion.div className='app-page-container pt54 zIndex2' {...SlideFromRight}>
            <div className='app-page-header'>
                <p className='app-page-header-title'>游戏榜单</p>
                <i className='app-page-header-back' onClick={goBack}></i>
            </div>
            <div className='app-page-content'>
                <Rank></Rank>
            </div>
        </motion.div>
    )
}

export default GameRank