import Http from "../../common/request";
import { ClientType } from "../../config";

export const getVipList = () => {
    return Http('/api/client/auth/vipList', { auth: true })
}

export const getTimeList = () => {
    return Http('/api/client/auth/timeList', { auth: true })
}

export const createVipOrder = (cid, ptype) => {
    return Http('/api/client/auth/pay/vipOrder', { auth: true, cid, ptype, ClientType })
}

export const queryVipOrder = orderid => {
    return Http('/api/client/auth/pay/vipOrder/status', { auth: true, orderid })
}

export const createTimeOrder = (cid, ptype) => {
    return Http('/api/client/auth/pay/timeOrder', { auth: true, cid, ptype, ClientType })
}

export const queryTimeOrder = orderid => {
    return Http('/api/client/auth/pay/timeOrder/status', { auth: true, orderid })
}

export const queryNewGuyGiftOrder = orderid => {
    return Http('/api/client/auth/pay/newGiftKit/order/status', { auth: true, orderid })
}

export const queryFreeCardOrder = orderid => {
    return Http('/api/client/auth/pay/timeCard/order/status', { auth: true, orderid })
}