import './index.css'
import { useState, useEffect, useRef, useContext } from 'react'
import { getTimeRecord } from './service'
import PubSub from 'pubsub-js'
import { EventTip } from '../../common/event'
import { getFullTime, getTime, getRem } from '../../common/util'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'
import AppContext from '../../common/context'

const PageSize = 10

function Time({ close, type }) {
    const { togglePay, setPayIndex } = useContext(AppContext)
    const [index, setIndex] = useState(0)
    const [record, setRecord] = useState([])
    const [page, setPage] = useState(1)
    const [ready, toggleReadyState] = useState(false)
    const hasMore = useRef(false)
    const loadFlag = useRef(false)

    useEffect(() => {
        const el = document.getElementById('app-time-record-window')
        const paddingBottom = getRem() * 0.46

        const listener = () => {
            if (!hasMore.current || loadFlag.current) {
                return
            }
            const { scrollHeight, clientHeight, scrollTop } = el
            if (scrollTop + clientHeight + paddingBottom >= scrollHeight) {
                setPage(p => p + 1)
            }
        }

        el.addEventListener('scroll', listener)

        return () => {
            el.removeEventListener('scroll', listener)
        }
    }, [])

    useEffect(() => {
        loadFlag.current = true
        getTimeRecord(index, page, PageSize, type).then(data => {
            const { list, total } = data
            if (page === 1) {
                setRecord(list)
            } else {
                setRecord(r => r.concat(list))
            }
            hasMore.current = list.length === PageSize && total > page * PageSize
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            toggleReadyState(true)
            loadFlag.current = false
        })
    }, [type, index, page])

    const changeIndex = i => {
        if (i !== index) {
            setPage(1)
            setIndex(i)
            setRecord([])
            toggleReadyState(false)
        }
    }

    const goToBuyTime = () => {
        setPayIndex(1)
        togglePay(true)
    }

    return (
        <motion.div className='app-page-container pt88' {...SlideFromRight}>
            <div className='app-page-header'>
                <p className='app-page-header-title'>{type === 1 ? '永久时长记录' : '时效时长记录'}</p>
                <i className='app-page-header-back' onClick={close}></i>
            </div>
            <div className='app-time-header'>
                <div className={index === 0 ? 'app-time-header-item active' : 'app-time-header-item'} onClick={() => changeIndex(0)}>获得记录</div>
                <div className={index === 1 ? 'app-time-header-item active' : 'app-time-header-item'} onClick={() => changeIndex(1)}>扣减记录</div>
            </div>
            <div className='app-time-content'>
                <div className='app-page-window' id='app-time-record-window'>
                    {
                        ready && record.length === 0 && <div className='app-time-empty-wrapper'>
                            <div className='app-time-empty-content'>
                                <i className='app-time-empty-icon'></i>
                                <p className='app-time-empty-tip'>一片荒芜~</p>
                                {
                                    index === 0 && <button className='app-time-empty-btn' onClick={goToBuyTime}>去获得</button>
                                }
                            </div>
                        </div>
                    }
                    {
                        ready && record.length > 0 && record.map(item => (
                            <div className='app-time-record' key={item.timestamp}>
                                <p className='app-time-record-remark'>{item.remark}</p>
                                {
                                    index === 0
                                        ? <p className='app-time-record-time'>领取：{getFullTime(item.timestamp)}</p>
                                        : <p className='app-time-record-time'>开始使用：{getFullTime(item.startTime)}</p>
                                }
                                {
                                    index === 0
                                        ? <p className='app-time-record-end-time'>到期：{type === 1 ? '无期限' : getFullTime(item.timestamp + (1000 * 60 * 60 * 24))}</p>
                                        : <p className='app-time-record-end-time'>结束使用：{getFullTime(item.endTime)}</p>
                                }
                                <div className='app-time-record-value-wrapper'>
                                    <p className='app-time-record-value-tip'>
                                        {index === 0
                                            ? '获得时长'
                                            : item.remark.indexOf('过期') >= 0
                                                ? '总时长'
                                                : '使用总时长'}
                                    </p>
                                    <p className='app-time-record-value'>{getTime(item.duration)}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </motion.div>
    )
}

export default Time