import './index.css'
import { useEffect, useState, useRef } from 'react'
import { getGameClassify, getGamesByClassify } from './service'
import PubSub from 'pubsub-js';
import { EventTip } from '../../common/event'
import CustomImage from '../../components/image';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getRem } from '../../common/util';

const PageSize = 16
const DefaultClassify = [
    { id: -1, atype: 0, name: '全部分类' }
    // ,{ id: -2, atype: 1, name: '免账号' },
    // { id: -3, atype: 2, name: '自备账号' }
]

function Store({ motionProp, storeIndex }) {
    const [gameClassify, setGameClassify] = useState([])
    const [index, setIndex] = useState(0)
    const [page, setPage] = useState(1)
    const [games, setGames] = useState([])
    const hasMore = useRef(false)
    const loadFlag = useRef(false)

    useEffect(() => {
        if (gameClassify.length) {
            loadFlag.current = true
            let { id, atype } = gameClassify[index]
            id < 0 && (id = 0)
            getGamesByClassify(id, page, PageSize, atype).then(data => {
                const { list, total } = data
                page === 1 ? setGames(list) : setGames(gs => gs.concat(list))
                hasMore.current = list.length === PageSize && total > page * PageSize
            }).catch(e => {
                PubSub.publish(EventTip, { msg: e.message })
            }).finally(() => {
                loadFlag.current = false
            })
        }
    }, [page, index, gameClassify])

    useEffect(() => {
        getGameClassify().then(data => {
            const cs = DefaultClassify.concat(data)
            if (storeIndex) {
                for (let i = 0; i < data.length; ++i) {
                    if (data[i].id === storeIndex) {
                        setIndex(i + 1)
                        break
                    }
                }
            }
            setGameClassify(cs)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
            setGameClassify(DefaultClassify)
        })
    }, [storeIndex])

    useEffect(() => {
        const el = document.getElementById('app-store-store-window')
        const paddingBottom = getRem() * 1.3

        const listener = () => {
            if (!hasMore.current || loadFlag.current) {
                return
            }
            const { clientHeight, scrollHeight, scrollTop } = el
            if (scrollTop + clientHeight + paddingBottom >= scrollHeight) {
                setPage(p => p + 1)
            }
        }

        el.addEventListener('scroll', listener)

        return () => {
            el.removeEventListener('scroll', listener)
        }
    }, [])

    const getClassNameByIndex = n => {
        if (index === n) {
            return 'app-store-nav-item active'
        } else if (index === n + 1) {
            return 'app-store-nav-item prev'
        } else if (index === n - 1) {
            return 'app-store-nav-item next'
        }
        return 'app-store-nav-item'
    }

    const loadGamesByClassify = n => {
        if (n !== index && !loadFlag.current) {
            setPage(1)
            setIndex(n)
            setGames([])
        }
    }

    return (
        <motion.div className='app-page-container pt54' {...motionProp}>
            <div className='app-store-wrapper'>
                <div className='app-store-nav-wrapper'>
                    <div className='app-page-window'>
                        <div className='app-store-ul-bottom'>
                            {
                                gameClassify.map((item, n) => (
                                    <div className={getClassNameByIndex(n)} key={item.id} onClick={() => loadGamesByClassify(n)}>{item.name}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='app-page-window' id='app-store-store-window'>
                    <ul className='app-store-ul-bottom'>
                        {
                            games.map(item => (
                                <Link to={`/game/${item.id}`} key={item.id} state={{ from: true }}>
                                    <li className='app-store-game-item'>
                                        <CustomImage className='app-store-game-item-img' src={item.iconh}></CustomImage>
                                        <p className='app-store-game-item-name'>{item.name}</p>
                                        <p className='app-store-game-item-desc'>{item.introduction}</p>
                                        <p className='app-store-game-item-tags'>{item.tag.map(t => t.name).join('·')}</p>
                                    </li>
                                </Link>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </motion.div>
    )
}

export default Store