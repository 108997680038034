import { useEffect } from "react";

function useZoomMode() {
    useEffect(() => {
        const listener = () => {
            const { innerHeight, innerWidth } = window
            const width = Math.min(innerWidth, innerHeight)
            document.documentElement.style.fontSize = `${(width * 100 / 375).toFixed(2)}px`
            document.documentElement.style.setProperty('--vh', `${innerHeight * 0.01}px`)
        }
        listener()
        window.addEventListener('resize', listener)
        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [])
}

export default useZoomMode