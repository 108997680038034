import './index.css'
import { motion } from 'framer-motion'
import { useContext, useState } from 'react'
import AppContext from '../../common/context'
import { SlideFromRight } from '../../animation'
import { getTime, getDay, getFullTime } from '../../common/util'
import Time from '../time'
import { AnimatePresence } from 'framer-motion'

function Property({ toggleShowState }) {
    const { user, togglePay, setPayIndex } = useContext(AppContext)
    const [detailType, setDetailType] = useState(1)
    const [showTimeDetail, toggleTimeDetail] = useState(false)

    const goBack = () => {
        toggleShowState(false)
    }

    const goToBuyVip = () => {
        setPayIndex(0)
        togglePay(true)
    }

    const goToBuyTime = () => {
        setPayIndex(1)
        togglePay(true)
    }

    const goToTimeDetail = () => {
        setDetailType(1)
        toggleTimeDetail(true)
    }

    const goToTempTimeDetail = () => {
        setDetailType(2)
        toggleTimeDetail(true)
    }

    const closeTimeDetail = () => {
        toggleTimeDetail(false)
    }

    const isVip = user.role === 1

    return (
        <>
            <motion.div className='app-page-container pt54 app-property-page' {...SlideFromRight}>
                <div className='app-page-header'>
                    <p className='app-page-header-title'>我的资产</p>
                    <i className='app-page-header-back' onClick={goBack}></i>
                </div>
                <div className='app-page-window'>
                    <div className='app-property-content-wrapper'>
                        <div className='app-property-vip-block'>
                            <div className='app-property-vip-block-title'>
                                <p className='app-property-vip-name'>
                                    VIP
                                    {
                                        isVip
                                            ? <span className='app-property-vip-flag valid'>已开通</span>
                                            : <span className='app-property-vip-flag'>未开通</span>
                                    }
                                </p>
                            </div>
                            <div className='app-property-vip-block-body'>
                                <button className='app-property-btn' onClick={goToBuyVip}>{isVip ? '续费' : '开通'}</button>
                            </div>
                            <div className='app-property-vip-block-content-wrapper'>
                                {
                                    isVip
                                        ? <p className='app-property-vip-block-content'>{getDay(user.vipTime)}<span className='app-property-vip-block-unit'>天有效期</span></p>
                                        : <p className='app-property-vip-block-content'>0<span className='app-property-vip-block-unit'>天</span></p>
                                }
                                {
                                    isVip && <p className='app-property-vip-time'>过期时间：{getFullTime(user.vipTime)}</p>
                                }
                            </div>
                        </div>
                        <div className='app-property-block'>
                            <p className='app-property-block-title'>永久高峰时长<span className='app-property-block-title-link' onClick={goToTimeDetail}>明细<i className='app-property-block-title-link-icon'></i></span></p>
                            <div className='app-property-block-body'>
                                <button className='app-property-btn' onClick={goToBuyTime}>补充时长</button>
                                <p className='app-property-block-content'>{getTime(user.peakDuration)}</p>
                            </div>
                        </div>
                        <div className='app-property-block'>
                            <p className='app-property-block-title'>时效高峰时长<span className='app-property-block-title-link' onClick={goToTempTimeDetail}>明细<i className='app-property-block-title-link-icon'></i></span></p>
                            <div className='app-property-block-body'>
                                <p className='app-property-block-content'>{getTime(user.tempDuration)}</p>
                            </div>
                        </div>
                        <div className='app-property-block'>
                            <p className='app-property-block-title'>畅玩到期时间</p>
                            <div className='app-property-block-body'>
                                <p className='app-property-block-content'>{Date.now() < user.timeCard ? getFullTime(user.timeCard) : '00:00:00'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <AnimatePresence>
                {
                    showTimeDetail && <Time type={detailType} close={closeTimeDetail}></Time>
                }
            </AnimatePresence>
        </>
    )
}

export default Property