import { getFromLocalStorage, setToLocalStorage, parseQueryString } from './util'
import { ChannelCacheKey, DefaultChannel } from '../config'

const AppChannel = (function () {
    const { cid } = parseQueryString(window.location.search)
    const ocid = getFromLocalStorage(ChannelCacheKey)
    const fcid = cid || ocid || DefaultChannel
    if (fcid !== ocid) {
        setToLocalStorage(ChannelCacheKey, fcid)
    }
    return fcid
})()

export default AppChannel