import './index.css'
import { useContext } from 'react'
import AppContext from '../../common/context'

function TimeDialog({ close, className }) {
    const { setPayIndex, togglePay } = useContext(AppContext)

    const action = () => {
        setPayIndex(1)
        togglePay(true)
        close()
    }

    return (
        <div className={className ? `app-page-container dialog ${className}` : 'app-page-container dialog'}>
            <div className='app-time-dialog-container'>
                <div className='app-time-dialog-content'>
                    <p className='app-time-dialog-title'>时长不足</p>
                    <p className='app-time-dialog-desc'>您剩余的游戏时长不足5分钟，请补充游戏时长</p>
                    <button className='app-time-dialog-btn' onClick={action}>去购买</button>
                    <div className='app-time-dialog-cancel' onClick={close}>下次再说</div>
                </div>
            </div>
        </div>
    )
}

export default TimeDialog