import './index.css'
import '../queue/index.css'
import { useState, useContext, useEffect, useRef } from 'react'
import AppContext from '../../common/context'
import Dialog from '../../components/dialog'
import CustomImage from '../../components/image'

const WaitingTime = 5000

function Waiting() {
    const { waitingInfo, showWaiting, toggleWaiting, togglePlayScreen } = useContext(AppContext)
    const [showGameOverDialog, toggleGameOverDialog] = useState(false)
    const startTimer = useRef(null)
    const closeTimer = useRef(null)

    useEffect(() => {
        if (showWaiting) {
            if (startTimer.current) {
                clearTimeout(startTimer.current)
            }
            if (closeTimer.current) {
                clearTimeout(closeTimer.current)
            }
            startTimer.current = setTimeout(() => {
                startTimer.current = null
                togglePlayScreen(true)
            }, WaitingTime / 2)
            closeTimer.current = setTimeout(() => {
                closeTimer.current = null
                toggleGameOverDialog(false)
                toggleWaiting(false)
            }, WaitingTime)
        }

        return () => {
            if (startTimer.current) {
                clearTimeout(startTimer.current)
                startTimer.current = null
            }
            if (closeTimer.current) {
                clearTimeout(closeTimer.current)
                closeTimer.current = null
            }
        }
    }, [showWaiting, toggleWaiting, togglePlayScreen])

    const openCancelDialog = () => {
        toggleGameOverDialog(true)
    }

    const closeCancelDialog = () => {
        toggleGameOverDialog(false)
    }

    const cancel = () => {
        closeCancelDialog()
        toggleWaiting(false)
    }

    if (!showWaiting) {
        return null
    }

    return (
        <>
            <div className='app-page-container zIndex5'>
                <div className='app-queue-page'>
                    <i className='app-queue-quit' onClick={openCancelDialog}></i>
                    <div className='app-queue-main'>
                        <div className='app-queue-main-top waiting'>
                            {
                                waitingInfo.channel === 1
                                    ? <p className='app-waiting-channel peak'>高峰时段</p>
                                    : <p className='app-waiting-channel normal'>普通时段</p>
                            }
                        </div>
                        <div className='app-queue-main-body'>
                            <div className='app-queue-main-body-content-wrapper'>
                                <CustomImage className='app-queue-main-body-content-bg' src={waitingInfo.gameImg}></CustomImage>
                                <div className='app-queue-main-body-content waiting'>
                                    <p className='app-waiting-tip'>游戏启动中...</p>
                                    <p className='app-waiting-tip'>请稍后</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='app-queue-bottom-tip-wrapper'>
                        <p className='app-queue-bottom-tip'>排队期间不消耗游戏时长</p>
                    </div>
                </div>
            </div>
            {
                showGameOverDialog && <Dialog title={`是否确认退出排队`} cancelFunc={closeCancelDialog} okFunc={cancel}></Dialog>
            }
        </>
    )
}

export default Waiting