import { useState, useRef, useEffect } from 'react'
import Index from '.'
import Store from '../store'
import Collect from '../collect'
import IndexHeader from './indexHeader'
import AppNav from '../../components/appNav'
import { Transition } from '../../animation'
import { AnimatePresence } from 'framer-motion'
import useNeedAuth from '../../hooks/useNeedAuth'

function App() {
    const [pageIndex, setPageIndex] = useState(0)
    const [aPageIndex, setAPageIndex] = useState(0)
    const [aProps, setAProps] = useState([{}, {}, {}])
    const [storeIndex, setStoreIndex] = useState(0)
    const oPageIndex = useRef(0)
    useNeedAuth()

    useEffect(() => {
        setPageIndex(aPageIndex)
    }, [aPageIndex])

    const changePage = index => {
        const oindex = oPageIndex.current
        if (index === oindex) {
            return
        }
        const naprops = [{}, {}, {}]
        if (index > oindex) {
            naprops[oindex] = { ...Transition, exit: { x: '-100vw' } }
            naprops[index] = { ...Transition, initial: { x: '100vw' }, animate: { x: '0vw' } }
        } else {
            naprops[oindex] = { ...Transition, exit: { x: '100vw' } }
            naprops[index] = { ...Transition, initial: { x: '-100vw' }, animate: { x: '0vw' } }
        }
        oPageIndex.current = index
        setAProps(naprops)
        setAPageIndex(index)
    }

    const goToPage = index => {
        if (index === 1 && oPageIndex.current !== 1) {
            setStoreIndex(0)
        }
        changePage(index)
    }

    const goToStore = index => {
        setStoreIndex(index)
        changePage(1)
    }

    return (
        <>
            <IndexHeader index={pageIndex} setIndex={goToPage}></IndexHeader>
            <AnimatePresence>
                {
                    pageIndex === 0 && <Index motionProp={aProps[0]} goToStore={goToStore}></Index>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    pageIndex === 1 && <Store motionProp={aProps[1]} storeIndex={storeIndex}></Store>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    pageIndex === 2 && <Collect motionProp={aProps[2]}></Collect>
                }
            </AnimatePresence>
            <AppNav index={0}></AppNav>
        </>
    )
}

export default App