import './trackItem.css'
import { useEffect } from 'react';
import { useMotionValue, motion, useTransform } from "framer-motion";
import { getRem } from '../../common/util'

function TrackItem({ lv, currentLv }) {
    const delta = useMotionValue(lv - currentLv)
    const x = useTransform(delta, latest => {
        if (latest >= -1 && latest <= 1) {
            return 1.3 * getRem() * latest
        }
        if (latest > 0) {
            return (1.3 + Math.min(1, latest - 1) * 0.3) * getRem()
        }
        return -(1.3 + Math.min(1, - latest - 1) * 0.3) * getRem()
    })
    const y = useTransform(x, latest => {
        const radius = 5.5 * getRem()
        return radius - Math.sqrt(Math.pow(radius, 2) - Math.pow(latest, 2))
    })
    const scale = useTransform(delta, latest => {
        latest = Math.abs(latest)
        if (latest <= 1) {
            return 1 - latest + (latest * 0.556)
        }
        latest -= 1
        return Math.max(0, (1 - latest) * 0.556)
    })
    const opacity = useTransform(delta, latest => {
        latest = Math.abs(latest)
        if (latest <= 1) {
            return 1 - latest + (latest * 0.4)
        }
        latest -= 1
        return Math.max(0, (1 - latest) * 0.4)
    })
    const fontScale = useTransform(delta, latest => {
        latest = Math.abs(latest)
        if (latest <= 1) {
            return 1 - latest + (latest * 0.714)
        }
        latest -= 1
        return Math.max(0, (1 - latest) * 0.714)
    })

    useEffect(() => {
        delta.set(lv - currentLv)
    }, [lv, currentLv, delta])

    return (
        <motion.div className="track-item-container" style={{ x, y, opacity }}>
            <motion.i className='track-item-icon' style={{ scale }}></motion.i>
            <motion.p className='track-item-txt' style={{ scale: fontScale }}>LV.{lv}</motion.p>
        </motion.div>
    )
}

export default TrackItem