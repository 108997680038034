import Http from './request'
import { getFromLocalStorage, safeParse, parseQueryString, setToLocalStorage } from './util'
import { UICacheKey, AuthCacheKey, LastCheckCacheKey, AppKey as _appKey, ClientType as _clientType, AppVersion as _version } from '../config'

export const getSystemConfig = () => {
    return Http('/api/public/config')
}

export const getUserInfo = () => {
    return Http('/api/client/auth/userInfo', { auth: true })
}

export const checkLoginState = () => {
    const token = getFromLocalStorage(AuthCacheKey)
    if (token) {
        return safeParse(getFromLocalStorage(UICacheKey), null)
    }
    return null
}

export const getWechatCode = () => {
    const { code } = parseQueryString(window.location.search)
    return code
}

export const loginByWechat = code => {
    window.history.replaceState(null, '', window.location.pathname)
    return Http('/api/wechat/login', {
        code,
        _timestamp: Date.now(),
        _appKey,
        _clientType,
        _version
    })
}

export const saveUserInfoToLocal = (token, userInfo, timestamp) => {
    setToLocalStorage(AuthCacheKey, token)
    setToLocalStorage(UICacheKey, userInfo)
    setToLocalStorage(LastCheckCacheKey, timestamp + '')
}