import { useContext, useCallback } from 'react'
import AppContext from '../../common/context'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'

const getAgreementTitle = type => {
    if (type === 1) {
        return '用户协议'
    }
    if (type === 2) {
        return '隐私政策'
    }
    return '充值协议'
}

const getAgreementLink = type => {
    if (type === 1) {
        return '/doc/service.html'
    }
    if (type === 2) {
        return '/doc/privacy-policy.html'
    }
    return '/doc/recharge.html'
}

function Agreement() {
    const { agreementType, toggleAgreement } = useContext(AppContext)
    const close = useCallback(() => {
        toggleAgreement(false)
    }, [toggleAgreement])

    const title = getAgreementTitle(agreementType)

    return (
        <motion.div className='app-page-container pt54 zIndex5' {...SlideFromRight}>
            <div className='app-page-header'>
                <p className='app-page-header-title'>{title}</p>
                <i className='app-page-header-back' onClick={close}></i>
            </div>
            <iframe src={getAgreementLink(agreementType)} title={title} className='app-frame-page'></iframe>
        </motion.div>
    )
}

export default Agreement