import './index.css'
import PubSub from 'pubsub-js'
import { EventUserInvalid, EventTip, EventType } from '../../common/event'
import { useEffect, useState, useRef } from 'react'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'
import { uploadImg, submitReport } from './service'
import CustomImage from '../../components/image'
import GroupDialog from '../../components/groupDialog'
import { QQGroup } from '../../config'

function Report({ close }) {
    const [inputMainTxt, setInputMainTxt] = useState('')
    const [images, setImages] = useState([])
    const [contacts, setContacts] = useState('')
    const [showGroupDialog, toggleGroupDialog] = useState(false)
    const submitFlag = useRef(false)

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [close])

    const inputMain = e => {
        const { value } = e.target
        setInputMainTxt(value)
    }

    const receiveImg = e => {
        const { files } = e.target
        if (files.length) {
            const formData = new FormData()
            formData.append('file', files[0])
            uploadImg(formData).then(data => {
                setImages(imgs => imgs.concat(data))
            }).catch(e => {
                PubSub.publish(EventTip, { msg: e.message })
            })
        }
    }

    const delImg = src => {
        setImages(imgs => imgs.filter(i => i !== src))
    }

    const input = e => {
        const { value } = e.target
        setContacts(value)
    }

    const cleanContacts = () => {
        setContacts('')
    }

    const submit = () => {
        if (!(inputMainTxt || '').trim()) {
            return PubSub.publish(EventTip, { msg: '请输入您要反馈的问题' })
        }
        if (!images.length) {
            return PubSub.publish(EventTip, { msg: '请上传相关截图' })
        }
        if (!(contacts || '').trim()) {
            return PubSub.publish(EventTip, { msg: '请输入您的联系方式' })
        }
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        submitReport(inputMainTxt, images, contacts).then(() => {
            PubSub.publish(EventTip, { msg: '提交成功', type: EventType.Success })
            close()
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const closeGroupDialog = () => {
        toggleGroupDialog(false)
    }

    const openGroupDialog = () => {
        toggleGroupDialog(true)
    }

    const invalid = !(inputMainTxt || '').trim() || !images.length || !(contacts || '').trim()

    return (
        <motion.div className='app-page-container pt56' {...SlideFromRight}>
            <div className='app-page-header'>
                <h1 className='app-page-header-title'>问题反馈</h1>
                <i className='app-page-header-back' onClick={close}></i>
                <div className='app-page-header-group' onClick={openGroupDialog}>加入内测群</div>
            </div>
            <div className='app-page-window'>
                <div className='app-report-content-wrapper'>
                    <p className='app-report-label require first'>问题描述</p>
                    <textarea className='app-report-ta' placeholder='请输入您要反馈的问题' value={inputMainTxt} onChange={inputMain}></textarea>
                    <p className='app-report-label require'>截图</p>
                    <div className='app-report-imgs-window'>
                        <div className='app-report-imgs-ul'>
                            <label className='app-report-img-form' htmlFor='app-report-img-form-hidden'>
                                <input type='file' accept='image/*' onChange={receiveImg} id='app-report-img-form-hidden' className='app-report-img-form-hidden'></input>
                            </label>
                            {
                                images.map(item => (
                                    <div className='app-report-img-item' key={item}>
                                        <CustomImage className='app-report-img' src={item}></CustomImage>
                                        <i className='app-report-img-cancel' onClick={() => delImg(item)}></i>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <p className='app-report-label require'>联系方式</p>
                    <div className='app-report-input-wrapper'>
                        <input className='app-report-input' placeholder='请输入您的微信/QQ/手机号' value={contacts} onChange={input}></input>
                        {
                            !!(contacts || '').trim() && <i className='app-report-input-del' onClick={cleanContacts}></i>
                        }
                    </div>
                    <div className='app-report-submit-btn-wrapper'>
                        <button className={invalid ? 'app-report-submit-btn disabled' : 'app-report-submit-btn'} onClick={submit}>提交</button>
                    </div>
                    <p className='app-report-group-txt'>您可加入QQ群参与交流：{QQGroup}</p>
                </div>
            </div>
            {
                showGroupDialog && <GroupDialog close={closeGroupDialog}></GroupDialog>
            }
        </motion.div>
    )
}

export default Report