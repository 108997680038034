import './index.css'
import { useState, useEffect, useRef } from 'react'
import { getCollection } from './service'
import BlockWindow from '../../components/blockWindow'
import PubSub from 'pubsub-js'
import { EventTip, EventLoginEffect } from '../../common/event'
import { motion } from 'framer-motion'
import { getRem } from '../../common/util'

const PageSize = 5

function Collect({ motionProp }) {
    const [collection, setCollection] = useState([])
    const [page, setPage] = useState(1)
    const [ready, toggleReadyState] = useState(false)
    const [refreshHelper, setRefreshHelper] = useState(1)
    const hasMore = useRef(false)
    const loadFlag = useRef(false)

    useEffect(() => {
        loadFlag.current = true
        getCollection(page, PageSize).then(data => {
            const { list, total } = data
            page === 1 ? setCollection(list) : setCollection(cs => cs.concat(list))
            hasMore.current = list.length === PageSize && total > page * PageSize
            toggleReadyState(!hasMore.current)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
            toggleReadyState(true)
        }).finally(() => {
            loadFlag.current = false
        })
    }, [page, refreshHelper])

    useEffect(() => {
        const el = document.getElementById('app-collection-window')
        const paddingBottom = getRem() * 1.3
        const listener = () => {
            if (!hasMore.current || loadFlag.current) {
                return
            }
            const { clientHeight, scrollHeight, scrollTop } = el
            if (scrollTop + clientHeight + paddingBottom >= scrollHeight) {
                setPage(p => p + 1)
            }
        }
        el.addEventListener('scroll', listener)

        const loginListener = PubSub.subscribe(EventLoginEffect, () => {
            setCollection([])
            setPage(1)
            toggleReadyState(false)
            setRefreshHelper(r => r + 1)
        })

        return () => {
            el.removeEventListener('scroll', listener)
            PubSub.unsubscribe(loginListener)
        }
    }, [])

    return (
        <motion.div className='app-page-container pt54' {...motionProp}>
            <div className='app-page-window' id='app-collection-window'>
                <div className='app-collection-page'>
                    {
                        collection.length > 0 && <BlockWindow className='app-collection-box' data={collection} ready={ready}></BlockWindow>
                    }
                    {
                        collection.length === 0 && ready && <div className='app-collection-empty-wrapper'>
                            <i className='app-collection-empty-icon'></i>
                            <p className='app-collection-empty-tip'>暂无收藏~</p>
                        </div>
                    }
                </div>
            </div>
        </motion.div>
    )
}

export default Collect