import './index.css'
import CustomImage from '../image'
import { Link } from 'react-router-dom'
import { memo } from 'react'

function BlockWindow({ data, ready, className = '' }) {
    return (
        <div className={className}>
            {
                data.map((item, n) => (
                    <div className='block-window-item-wrapper' key={`${n}-${item.id}`}>
                        <div className='block-window-item'>
                            <CustomImage className='block-window-item-img' src={item.iconh}></CustomImage>
                            <div className='block-window-item-info'>
                                <p className={item.status === 0 ? 'block-window-item-name disabled' : 'block-window-item-name'}>{item.name}</p>
                                <p className='block-window-item-desc'>{item.introduction}</p>
                                <div className='block-window-item-v-img-wrapper'>
                                    <CustomImage className='block-window-item-v-img' src={item.iconv}></CustomImage>
                                </div>
                            </div>
                            <div className='block-window-item-bottom'>
                                <div className='block-window-item-tags-wrapper'>
                                    {
                                        item.tag.map(tag => (
                                            <div className='block-window-item-tag' key={tag.id}>{tag.name}</div>
                                        ))
                                    }
                                </div>
                                {
                                    item.status === 0
                                        ? <button className='block-window-item-btn disabled'>秒玩</button>
                                        : <Link to={`/game/${item.id}`} state={{ from: true }}>
                                            <button className='block-window-item-btn'>秒玩</button>
                                        </Link>
                                }
                            </div>
                        </div>
                        {
                            item.status === 0 && <div className='block-window-item-flag'>已下架</div>
                        }
                    </div>
                ))
            }
            {
                ready && <p className='block-window-no-more-tip'>没有更多了~</p>
            }
        </div>
    )
}

export default memo(BlockWindow)