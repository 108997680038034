import './result.css'
import './index.css'
import { useContext } from 'react'
import AppContext from '../../common/context'

function AuthResult({ close }) {
    const { user } = useContext(AppContext)

    return (
        <div className='app-page-container dialog'>
            <div className='app-auth-result-container'>
                <p className='app-auth-title'>实名认证</p>
                <i className='app-auth-close' onClick={close}></i>
                <i className='app-auth-result-icon'></i>
                <p className='app-auth-result-tip'>当前账号已实名</p>
                <div className='app-auth-result-info'>
                    <div className='app-auth-result-line'>
                        <span className='app-auth-result-label'>姓名</span>
                        <span className='app-auth-result-content'>{user.realName}</span>
                    </div>
                    <div className='app-auth-result-line'>
                        <span className='app-auth-result-label'>身份证号</span>
                        <span className='app-auth-result-content'>{user.realID}</span>
                    </div>
                </div>
                <div className='app-auth-result-btn-wrapper'>
                    <button className='app-auth-result-btn' onClick={close}>确定</button>
                </div>
            </div>
        </div>
    )
}

export default AuthResult