const KeyboardBtns = [
    {
        "id": 1,
        "name": "Esc",
        "value": 27
    },
    {
        "id": 2,
        "name": "F1",
        "value": 112
    },
    {
        "id": 3,
        "name": "F2",
        "value": 113
    },
    {
        "id": 4,
        "name": "F3",
        "value": 114
    },
    {
        "id": 5,
        "name": "F4",
        "value": 115
    },
    {
        "id": 6,
        "name": "F5",
        "value": 116
    },
    {
        "id": 7,
        "name": "F6",
        "value": 117
    },
    {
        "id": 8,
        "name": "F7",
        "value": 118
    },
    {
        "id": 9,
        "name": "F8",
        "value": 119
    },
    {
        "id": 10,
        "name": "F9",
        "value": 120
    },
    {
        "id": 11,
        "name": "F10",
        "value": 121
    },
    {
        "id": 12,
        "name": "F11",
        "value": 122
    },
    {
        "id": 13,
        "name": "F12",
        "value": 123
    },
    {
        "id": 14,
        "name": "Ins",
        "value": 45
    },
    {
        "id": 15,
        "name": "Del",
        "value": 46
    },
    {
        "id": 16,
        "name": "PaUp",
        "value": 33
    },
    {
        "id": 17,
        "name": "PaDn",
        "value": 34
    },
    {
        "id": 18,
        "name": "Home",
        "value": 36
    },
    {
        "id": 19,
        "name": "End",
        "value": 35
    },
    {
        "id": 20,
        "name": "`",
        "value": 192
    },
    {
        "id": 21,
        "name": "1",
        "value": 49
    },
    {
        "id": 22,
        "name": "2",
        "value": 50
    },
    {
        "id": 23,
        "name": "3",
        "value": 51
    },
    {
        "id": 24,
        "name": "4",
        "value": 52
    },
    {
        "id": 25,
        "name": "5",
        "value": 53
    },
    {
        "id": 26,
        "name": "6",
        "value": 54
    },
    {
        "id": 27,
        "name": "7",
        "value": 55
    },
    {
        "id": 28,
        "name": "8",
        "value": 56
    },
    {
        "id": 29,
        "name": "9",
        "value": 57
    },
    {
        "id": 30,
        "name": "0",
        "value": 48
    },
    {
        "id": 31,
        "name": "-",
        "value": 189
    },
    {
        "id": 32,
        "name": "=",
        "value": 187
    },
    {
        "id": 33,
        "name": "Back",
        "value": 8
    },
    {
        "id": 34,
        "name": "/",
        "value": 111
    },
    {
        "id": 35,
        "name": "*",
        "value": 106
    },
    {
        "id": 36,
        "name": "-",
        "value": 109
    },
    {
        "id": 37,
        "name": "+",
        "value": 107
    },
    {
        "id": 38,
        "name": "Tab",
        "value": 9
    },
    {
        "id": 39,
        "name": "Q",
        "value": 81
    },
    {
        "id": 40,
        "name": "W",
        "value": 87
    },
    {
        "id": 41,
        "name": "E",
        "value": 69
    },
    {
        "id": 42,
        "name": "R",
        "value": 82
    },
    {
        "id": 43,
        "name": "T",
        "value": 84
    },
    {
        "id": 44,
        "name": "Y",
        "value": 89
    },
    {
        "id": 45,
        "name": "U",
        "value": 85
    },
    {
        "id": 46,
        "name": "I",
        "value": 73
    },
    {
        "id": 47,
        "name": "O",
        "value": 79
    },
    {
        "id": 48,
        "name": "P",
        "value": 80
    },
    {
        "id": 49,
        "name": "[",
        "value": 219
    },
    {
        "id": 50,
        "name": "]",
        "value": 221
    },
    {
        "id": 51,
        "name": "\\",
        "value": 220
    },
    {
        "id": 52,
        "name": "7",
        "value": 103
    },
    {
        "id": 53,
        "name": "8",
        "value": 104
    },
    {
        "id": 54,
        "name": "9",
        "value": 105
    },
    {
        "id": 55,
        "name": "Caps",
        "value": 20
    },
    {
        "id": 56,
        "name": "A",
        "value": 65
    },
    {
        "id": 57,
        "name": "S",
        "value": 83
    },
    {
        "id": 58,
        "name": "D",
        "value": 68
    },
    {
        "id": 59,
        "name": "F",
        "value": 70
    },
    {
        "id": 60,
        "name": "G",
        "value": 71
    },
    {
        "id": 61,
        "name": "H",
        "value": 72
    },
    {
        "id": 62,
        "name": "J",
        "value": 74
    },
    {
        "id": 63,
        "name": "K",
        "value": 75
    },
    {
        "id": 64,
        "name": "L",
        "value": 76
    },
    {
        "id": 65,
        "name": ";",
        "value": 186
    },
    {
        "id": 66,
        "name": "'",
        "value": 222
    },
    {
        "id": 67,
        "name": "Enter",
        "value": 13
    },
    {
        "id": 68,
        "name": "4",
        "value": 100
    },
    {
        "id": 69,
        "name": "5",
        "value": 101
    },
    {
        "id": 70,
        "name": "6",
        "value": 102
    },
    {
        "id": 71,
        "name": "Enter",
        "value": 13
    },
    {
        "id": 72,
        "name": "Shift",
        "value": 16
    },
    {
        "id": 73,
        "name": "Z",
        "value": 90
    },
    {
        "id": 74,
        "name": "X",
        "value": 88
    },
    {
        "id": 75,
        "name": "C",
        "value": 67
    },
    {
        "id": 76,
        "name": "V",
        "value": 86
    },
    {
        "id": 77,
        "name": "B",
        "value": 66
    },
    {
        "id": 78,
        "name": "N",
        "value": 78
    },
    {
        "id": 79,
        "name": "M",
        "value": 77
    },
    {
        "id": 80,
        "name": ",",
        "value": 188
    },
    {
        "id": 81,
        "name": ".",
        "value": 190
    },
    {
        "id": 82,
        "name": "/",
        "value": 191
    },
    {
        "id": 83,
        "name": "Shift",
        "value": 16
    },
    {
        "id": 84,
        "name": "↑",
        "value": 38
    },
    {
        "id": 85,
        "name": "1",
        "value": 97
    },
    {
        "id": 86,
        "name": "2",
        "value": 98
    },
    {
        "id": 87,
        "name": "3",
        "value": 99
    },
    {
        "id": 88,
        "name": "Ctrl",
        "value": 17
    },
    {
        "id": 89,
        "name": "Alt",
        "value": 18
    },
    {
        "id": 90,
        "name": "Space",
        "value": 32
    },
    {
        "id": 91,
        "name": "Alt",
        "value": 18
    },
    {
        "id": 92,
        "name": "Ctrl",
        "value": 17
    },
    {
        "id": 93,
        "name": "←",
        "value": 37
    },
    {
        "id": 94,
        "name": "↓",
        "value": 40
    },
    {
        "id": 95,
        "name": "→",
        "value": 39
    },
    {
        "id": 96,
        "name": "0",
        "value": 96
    },
    {
        "id": 97,
        "name": ".",
        "value": 110
    }
]

export default KeyboardBtns