import { createContext } from "react";

export const defaultContext = {
    showLoginPage: false,
    toggleLoginPage() { },
    showSmsPage: false,
    toggleSmsPage() { },
    isLogin: false,
    toggleLoginState() { },
    bindPhone: false,
    toggleBindPhone() { },
    user: {},
    setUser() { },
    loginPhone: '',
    setLoginPhone() { },
    showPlayScreen: false,
    togglePlayScreen() { },
    showPay: false,
    togglePay() { },
    payIndex: 0,
    setPayIndex() { },
    game: {},
    setGame() { },
    sysConfig: {},
    setSysConfig() { },
    userReadyState: false,
    toggleUserReadyState() { },
    newGuyDuration: 0,
    setNewGuyDuration() { },
    showNewGuyDuration: false,
    toggleNewGuyDuration() { },
    showFreeCardEntry: true,
    toggleFreeCardEntry() { },
    freeCardList: [],
    setFreeCardList() { },
    showFreeCard: false,
    toggleFreeCard() { },
    showWaiting: false,
    toggleWaiting() { },
    waitingInfo: {},
    setWaitingInfo() { },
    lvDetailFromVertical: true,
    toggleLvDetailFromVertical() { },
    showLvDetail: false,
    toggleLvDetail() { },
    showAgreement: false,
    toggleAgreement() { },
    agreementType: 1,
    setAgreementType() { },
    showSignIn: false,
    toggleSignIn() { },
    signInFromVertical: true,
    toggleSignInFromVertical() { }
}

const AppContext = createContext(defaultContext)

export default AppContext