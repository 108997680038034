import './index.css'
import CustomImage from '../../components/image'
import { useContext, useEffect, useState, useRef } from 'react'
import AppContext from '../../common/context'
import PubSub from 'pubsub-js'
import { EventTip, EventUserInvalid, EventType, EventRefreshUser } from '../../common/event'
import DefaultAvatar from '../../assets/uc/avatar.png'
import { getMyCDKEY, exchangeCDKEY } from './service'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'
import GroupDialog from '../../components/groupDialog'

const HiddenInput = 'app-cdkey-code-hidden'

function CDKEY({ close }) {
    const [exp, setExp] = useState('')
    const [duration, setDuration] = useState('')
    const [code, setCode] = useState('')
    const [inputCode, setInputCode] = useState('')
    const [showCodeTip, toggleCodeTip] = useState(false)
    const [codeTip, setCodeTip] = useState('')
    const [showGroupDialog, toggleGroupDialog] = useState(false)
    const { user } = useContext(AppContext)
    const receiveFlag = useRef(false)

    useEffect(() => {
        getMyCDKEY().then(data => {
            const { code, duration, fromScore } = data
            setExp(fromScore + '')
            setDuration(duration + '')
            setCode(code)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        })
    }, [])

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [close])

    const copyCode = () => {
        document.getElementById(HiddenInput).select()
        document.execCommand('copy')
        PubSub.publish(EventTip, { msg: '复制成功', type: EventType.Success })
    }

    const input = e => {
        const { value } = e.target
        setInputCode(value)
    }

    const submit = () => {
        const value = (inputCode || '').trim()
        if (!value) {
            return
        }
        if (receiveFlag.current) {
            return
        }
        receiveFlag.current = true
        exchangeCDKEY(value).then(data => {
            const { duration } = data
            PubSub.publish(EventTip, { msg: `兑换成功，高峰时长+${duration}分钟`, type: EventType.Success })
            PubSub.publish(EventRefreshUser)
            setInputCode('')
        }).catch(e => {
            setCodeTip(e.message)
            toggleCodeTip(true)
        }).finally(() => {
            receiveFlag.current = false
        })
    }

    const closeRepeatTip = () => {
        toggleCodeTip(false)
    }

    const closeGroupDialog = () => {
        toggleGroupDialog(false)
    }

    const openGroupDialog = () => {
        toggleGroupDialog(true)
    }

    return (
        <motion.div className='app-page-container app-cdkey-page' {...SlideFromRight}>
            <div className='app-page-header-group cdk' onClick={openGroupDialog}>加入内测群</div>
            <i className='app-cdkey-close' onClick={close}></i>
            <div className='app-page-window'>
                <div className='app-cdkey-page-wrapper'>
                    <div className='app-cdkey-content'>
                        <div className='app-cdkey-avatar-wrapper'>
                            <CustomImage className='app-cdkey-avatar' src={user.avatar || DefaultAvatar}></CustomImage>
                        </div>
                        <p className='app-cdkey-user-name'>{user.nickName}</p>
                        <p className='app-cdkey-main-tip'>邀好友得经验值<span className='app-cdkey-main-tip-value'>{exp}</span>/位</p>
                        <p className='app-cdkey-tip'>【好友获{duration}分钟高峰时长】</p>
                        <div className='app-cdkey-code-wrapper'>
                            <div className='app-cdkey-code-line'>
                                <p className='app-cdkey-code-value'>{code}</p>
                                <button className='app-cdkey-code-copy-btn' onClick={copyCode}>复制</button>
                                <textarea className={HiddenInput} id={HiddenInput} value={code} readOnly></textarea>
                            </div>
                        </div>
                        <div className='app-cdkey-invite-btn-wrapper'>
                            <button className='app-cdkey-invite-btn'>邀请注册</button>
                        </div>
                        <div className='app-cdkey-receive-wrapper'>
                            <p className='app-cdkey-receive-title'>兑换福利</p>
                            <div className='app-cdkey-receive-box'>
                                <input className='app-cdkey-receive-input' value={inputCode} placeholder='输入邀请码' onChange={input}></input>
                                <button className={(inputCode || '').trim() ? 'app-cdkey-receive-btn' : 'app-cdkey-receive-btn disabled'} onClick={submit}>确定</button>
                            </div>
                        </div>
                    </div>
                    <div className='app-cdkey-guide'></div>
                </div>
            </div>
            {
                showCodeTip && <div className='app-page-container dialog nest'>
                    <div className='app-cdkey-dialog-wrapper'>
                        <p className='app-cdkey-dialog-title'>{codeTip}</p>
                        <button className='app-cdkey-dialog-btn' onClick={closeRepeatTip}>知道了</button>
                    </div>
                </div>
            }
            {
                showGroupDialog && <GroupDialog close={closeGroupDialog}></GroupDialog>
            }
        </motion.div>
    )
}

export default CDKEY