import './index.css'
import { useState, useEffect, useRef } from 'react'
import { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage, safeParse } from '../../common/util'
import { Link } from 'react-router-dom'
import { SearchHistoryCacheKey } from '../../config'
import { searchGames } from './service'
import PubSub from 'pubsub-js'
import { EventTip } from '../../common/event'
import CustomImage from '../../components/image'
import useSafeBack from '../../hooks/useSafeBack'

const insertSearchHistory = keyword => {
    const searchHistory = safeParse(getFromLocalStorage(SearchHistoryCacheKey), null)
    if (!Array.isArray(searchHistory) || !searchHistory.length) {
        return setToLocalStorage(SearchHistoryCacheKey, [keyword])
    }
    setToLocalStorage(SearchHistoryCacheKey, [keyword].concat(searchHistory.filter(h => h !== keyword)))
}

function Search() {
    const [keyword, setKeyword] = useState('')
    const [searchList, setSearchList] = useState([])
    const [searchResult, setSearchResult] = useState([])
    const [readyState, setReadyState] = useState(-1)
    const searchFlag = useRef(false)
    const safeBack = useSafeBack()

    useEffect(() => {
        const searchHistory = safeParse(getFromLocalStorage(SearchHistoryCacheKey), null)
        if (Array.isArray(searchHistory) && searchHistory.length) {
            setSearchList(searchHistory)
        }
        setReadyState(0)
        document.getElementById('app-search-input').focus()
    }, [])

    const input = e => {
        const { value } = e.target
        setKeyword(value)
        if (readyState === 1 && !value) {
            const searchHistory = safeParse(getFromLocalStorage(SearchHistoryCacheKey), null)
            if (Array.isArray(searchHistory) && searchHistory.length) {
                setSearchList(searchHistory)
            }
            setReadyState(0)
        }
    }

    const searchByValue = value => {
        if (searchFlag.current) {
            return
        }
        searchFlag.current = true
        insertSearchHistory(value)
        searchGames(value).then(data => {
            setReadyState(1)
            setSearchResult(data)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            searchFlag.current = false
        })
    }

    const search = () => {
        const value = (keyword || '').trim()
        if (!value) {
            return
        }
        searchByValue(value)
    }

    const enter = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            search()
        }
    }

    const searchByHistory = value => {
        setKeyword(value)
        searchByValue(value)
    }

    const clear = () => {
        setSearchList([])
        removeFromLocalStorage(SearchHistoryCacheKey)
    }

    return (
        <div className='app-page-container pt56'>
            <div className='app-search-header'>
                <div className='app-search-back' onClick={safeBack}>
                    <i className='app-search-back-icon'></i>
                </div>
                <div className='app-search-input-wrapper'>
                    <i className='app-search-icon'></i>
                    <input className='app-search-input' id='app-search-input' placeholder='请输入想玩的游戏名称' value={keyword} onChange={input} onKeyDown={enter}></input>
                </div>
                <div className='app-search-btn-wrapper' onClick={search}>搜索</div>
            </div>
            <div className='app-page-content'>
                <div className='app-page-window'>
                    {
                        readyState <= 0 && <div className='app-search-history-title'><i className='app-search-history-clear' onClick={clear}></i>历史记录</div>
                    }
                    {
                        readyState === 0 && searchList.length === 0 && <p className='app-search-history-empty-tip'>暂无历史搜索</p>
                    }
                    {
                        readyState === 0 && searchList.length > 0 && <ul className='app-search-history-ul'>
                            {
                                searchList.map(item => (
                                    <li key={item} className='app-search-history-item' onClick={() => searchByHistory(item)}>{item}</li>
                                ))
                            }
                        </ul>
                    }
                    {
                        readyState === 1 && searchResult.length === 0 && <div className='app-search-empty-wrapper'>
                            <i className='app-search-empty-icon'></i>
                            <p className='app-search-empty-tip'>抱歉，没有找到相关游戏</p>
                        </div>
                    }
                    {
                        readyState === 1 && searchResult.length > 0 && <ul>
                            {
                                searchResult.map(item => (
                                    <Link to={`/game/${item.id}`} key={item.id} state={{ from: true }}>
                                        <li className='app-search-result-item' key={item.id}>
                                            <CustomImage className='app-search-result-item-img' src={item.iconh}></CustomImage>
                                            <div className='app-search-result-item-info'>
                                                <p className='app-search-result-item-name'>{item.name}</p>
                                                <p className='app-search-result-item-desc'>{item.introduction}</p>
                                                <p className='app-search-result-item-tags'>{item.tag.map(t => t.name).join('·')}</p>
                                            </div>
                                            <button className='app-search-result-item-link'>秒玩</button>
                                        </li>
                                    </Link>
                                ))
                            }
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default Search