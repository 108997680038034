import './index.css'
import { useEffect, useState, useRef } from 'react'
import { getPacketRecord, receivePacket } from './service'
import PubSub from 'pubsub-js'
import { EventUserInvalid, EventTip, EventRefreshUser } from '../../common/event'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'

const MotionDuration = 3000

function Packet({ close }) {
    const [record, setRecord] = useState([])
    const [showRecord, setShowRecord] = useState([])
    const [inputCode, setInputCode] = useState('')
    const [recordIndex, setRecordIndex] = useState(0)
    const [resultValue, setResultValue] = useState('')
    const [showResult, toggleResult] = useState(false)
    const timer = useRef(null)
    const submitFlag = useRef(false)

    useEffect(() => {
        getPacketRecord().then(data => {
            if (Array.isArray(data) && data.length) {
                if (timer.current) {
                    clearTimeout(timer.current)
                }
                setRecord(data)
                setShowRecord([data[0]])
                timer.current = setTimeout(() => {
                    setRecordIndex(1)
                    timer.current = null
                }, MotionDuration)
            }
        })

        return () => {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        }
    }, [])

    useEffect(() => {
        if (recordIndex) {
            const index = recordIndex % record.length
            setShowRecord(sr => sr.concat(record[index]))
        }
    }, [record, recordIndex])

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [close])

    const input = e => {
        const { value } = e.target
        setInputCode(value)
    }

    const nextAnimation = () => {
        setShowRecord(sr => sr.slice(-1))
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            setRecordIndex(i => i + 1)
            timer.current = null
        }, MotionDuration)
    }

    const submit = () => {
        const value = (inputCode || '').trim()
        if (!value || submitFlag.current) {
            return
        }
        submitFlag.current = true
        receivePacket(value).then(data => {
            setResultValue(data.duration + '')
            toggleResult(true)
            PubSub.publish(EventRefreshUser)
            setInputCode('')
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const closeResult = () => {
        toggleResult(false)
    }

    return (
        <motion.div className='app-page-container app-packet-page' {...SlideFromRight}>
            <i className='app-popup-close safe-area' onClick={close}></i>
            <div className='app-packet-container'>
                <div className='app-packet-record-window'>
                    <ul className={showRecord.length > 1 ? 'app-packet-record-ul animation' : 'app-packet-record-ul'} onAnimationEnd={nextAnimation}>
                        {
                            showRecord.map((item, n) => (
                                <li className='app-packet-record-item' key={n}>{item.uname}领取了<span className='app-packet-record-highlight'>{item.duration}分钟</span>的红包</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className='app-packet-input-wrapper'>
                <input className='app-packet-input' placeholder='请输入红包口令' value={inputCode} onChange={input}></input>
            </div>
            <div className={(inputCode || '').trim() ? 'app-packet-btn-wrapper' : 'app-packet-btn-wrapper disabled'}>
                <button className='app-packet-btn' onClick={submit}></button>
            </div>
            {
                showResult && <div className='app-page-container dialog nest'>
                    <div className='app-packet-result-container'>
                        <div className='app-packet-result-title-wrapper'>
                            <span className='app-packet-result-title'>恭喜获得</span>
                        </div>
                        <p className='app-packet-result-content'><span className='highlight'>{resultValue}分钟</span>红包</p>
                        <p className='app-packet-result-tip'>时效高峰时长次日到期，尽快使用噢~</p>
                        <div className='app-packet-result-btn-wrapper'>
                            <button className='app-packet-result-btn' onClick={closeResult}>知道了</button>
                        </div>
                    </div>
                </div>
            }
        </motion.div>
    )
}

export default Packet