import Http from '../../common/request'

export const getIndexData = () => {
    return Http('/api/client/indexData')
}

export const getHotNewGuyGift = () => {
    return Http('/api/client/auth/newGiftKit/hot', { auth: true, silent: true })
}

export const getFreeCardList = () => {
    return Http('/api/client/auth/timeCards', { auth: true, silent: true })
}