import './index.css'
import { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { getTimeList, getVipList, createVipOrder, createTimeOrder } from './service'
import { motion } from 'framer-motion'
import { SlideFromBottomAuto } from '../../animation'
import { getHour } from '../../common/util'
import PubSub from 'pubsub-js'
import { EventTip, EventPayStart, EventPayEnd } from '../../common/event'
import AppContext from '../../common/context'
import { goToPay } from '../../config'

function Pay() {
    const { togglePay, payIndex, setAgreementType, toggleAgreement } = useContext(AppContext)
    const [index, setIndex] = useState(payIndex)
    const [vipList, setVipList] = useState([])
    const [vipIndex, setVipIndex] = useState(0)
    const [timeList, setTimeList] = useState([])
    const [timeIndex, setTimeIndex] = useState(0)
    const [payTypeIndex, setPayTypeIndex] = useState(0)
    const submitFlag = useRef(false)
    const close = useCallback(() => {
        togglePay(false)
    }, [togglePay])

    useEffect(() => {
        getVipList().then(data => {
            const { first, list } = data
            setVipList(list.filter(i => i.status === 1).map(i => ({ ...i, first })))
        })
        getTimeList().then(data => {
            const { first, list } = data
            setTimeList(list.filter(i => i.status === 1).map(i => ({ ...i, first, time: getHour(i.durations) })))
        })
    }, [])

    useEffect(() => {
        const listener = PubSub.subscribe(EventPayEnd, close)

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [close])

    const getVipClassName = (item, n) => {
        let ret = 'app-pay-vip-item'
        if (item.first === 1) {
            ret += ' new-guy'
        }
        if (n === vipIndex) {
            ret += ' active'
        }
        return ret
    }

    const getTimeClassName = (item, n) => {
        let ret = 'app-pay-time-item'
        if (item.first === 1) {
            ret += ' new-guy'
        }
        if (n === timeIndex) {
            ret += ' active'
        }
        return ret
    }

    const submit = () => {
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        const ptype = payTypeIndex === 0 ? 2 : 1
            ; (index === 0 ? createVipOrder(vipList[vipIndex].id, ptype) : createTimeOrder(timeList[timeIndex].id, ptype)).then(data => {
                goToPay(data.payResult, ptype)
                PubSub.publish(EventPayStart, { type: index, orderid: data.orderid })
            }).catch(e => {
                PubSub.publish(EventTip, { msg: e.message })
            }).finally(() => {
                submitFlag.current = false
            })
    }

    const goToRechargeAgreement = () => {
        setAgreementType(3)
        toggleAgreement(true)
    }

    const chosen = (index === 0 ? vipList[vipIndex] : timeList[timeIndex]) || {}

    return (
        <div className='app-page-container pay dialog'>
            <motion.div className='app-drawer-container' {...SlideFromBottomAuto}>
                {/* <div className='app-drawer-promo'></div> */}
                <i className='app-drawer-close' onClick={close}></i>
                <div className={`app-pay-container index-${index}`}>
                    <div className='app-pay-header'>
                        <button className={index === 0 ? 'app-pay-header-btn active' : 'app-pay-header-btn'} onClick={() => setIndex(0)}>会员</button>
                        <button className={index === 1 ? 'app-pay-header-btn active' : 'app-pay-header-btn'} onClick={() => setIndex(1)}>时长</button>
                    </div>
                    <div className={index === 0 ? 'app-pay-vip-body' : 'app-pay-vip-body hidden'}>
                        <div className='app-pay-vip-window'>
                            <div className='app-pay-vip-ul'>
                                {
                                    vipList.map((item, n) => (
                                        <div className={getVipClassName(item, n)} key={item.id} onClick={() => setVipIndex(n)}>
                                            <div className='app-pay-vip-item-upper'>
                                                <p className='app-pay-vip-days-num'>{item.days}天会员</p>
                                                <p className='app-pay-vip-price'><span className='app-pay-vip-price-unit'>&yen;</span>{item.first === 1 ? item.androidNewPrice : item.androidPrice}</p>
                                                <p className='app-pay-vip-original-price'>&yen;{item.price}</p>
                                            </div>
                                            <div className='app-pay-vip-item-lower'>每日赠送{item.presenterTime}分钟游戏时长</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='app-pay-vip-right-wrapper'>
                            <i className='app-pay-vip-right-icon'></i>
                            <ul className='app-pay-vip-right-ul'>
                                <li className='app-pay-vip-right-item first-line'>
                                    <p className='app-pay-vip-right-txt'>VIP专属签到时长</p>
                                </li>
                                <li className='app-pay-vip-right-item first-line'>
                                    <p className='app-pay-vip-right-txt first-line'>VIP双倍成长值</p>
                                    <p className='app-pay-vip-right-desc'>首个会员期间3倍</p>
                                </li>
                                <li className='app-pay-vip-right-item'>
                                    <p className='app-pay-vip-right-txt'>全游戏自定义按键</p>
                                </li>
                                <li className='app-pay-vip-right-item'>
                                    <p className='app-pay-vip-right-txt'>VIP排队加速</p>
                                </li>
                                <li className='app-pay-vip-right-item'>
                                    <p className='app-pay-vip-right-txt'>全游戏蓝光画质</p>
                                </li>
                                <li className='app-pay-vip-right-item'>
                                    <p className='app-pay-vip-right-txt'>购买时长赠送时长</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={index === 1 ? 'app-pay-time-body' : 'app-pay-time-body hidden'}>
                        <div className='app-pay-time-tip'></div>
                        <p className='app-pay-time-tip-txt'>购买后不过期，会员购买额外送时长</p>
                        <div className='app-pay-time-ul'>
                            {
                                timeList.map((item, n) => (
                                    <div className={getTimeClassName(item, n)} key={item.id} onClick={() => setTimeIndex(n)}>
                                        <div className='app-pay-time-item-upper'>
                                            <p className='app-pay-time-item-info'>{item.time}<span className='app-pay-time-item-price'>&yen;{item.first === 1 ? item.androidNewPrice : item.androidPrice}</span></p>
                                            <p className='app-pay-time-item-unit-price'><span className='app-pay-time-item-unit-price-value'>&yen;{item.convertPrice}</span>/小时</p>
                                        </div>
                                        <div className='app-pay-time-item-lower'>会员多赠送<span className='app-pay-time-item-gift-value'>{item.presenterTime}</span>分钟</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={`app-pay-types-wrapper index-${index}`}>
                        <div className='app-pay-type' onClick={() => setPayTypeIndex(0)}>
                            <i className={payTypeIndex === 0 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                            <i className='app-pay-type-ali-icon'></i>
                            <p className='app-pay-type-info'><i className='app-pay-type-icon ali'></i>支付宝</p>
                        </div>
                        <div className='app-pay-type' onClick={() => setPayTypeIndex(1)}>
                            <i className={payTypeIndex === 1 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                            <p className='app-pay-type-info'><i className='app-pay-type-icon wechat'></i>微信</p>
                        </div>
                    </div>
                    <div className={`app-pay-btn-wrapper index-${index}`}>
                        <button className='app-pay-btn' onClick={submit}>
                            <span className='app-pay-btn-txt'>立即购买</span>
                            <span className='app-pay-btn-price'>售价&yen;</span>
                            <span className='app-pay-btn-price-value'>{chosen.first === 1 ? chosen.androidNewPrice : chosen.androidPrice}</span>
                            <span className='app-pay-btn-price-original'>&yen;{chosen.price}</span>
                        </button>
                    </div>
                    <p className='app-pay-bottom-tip'>支付即视为阅读并同意<span className='app-pay-bottom-tip-agreement' onClick={goToRechargeAgreement}>《用户充值协议》</span></p>
                </div>
            </motion.div>
        </div>
    )
}

export default Pay