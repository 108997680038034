import './index.css'
import { Link } from 'react-router-dom'
import CustomImage from '../image'
import { memo } from 'react'

function CommonWindow({ data }) {
    return (
        <div className='common-window-wrapper'>
            <div className='common-window-box'>
                {
                    data.map((item, n) => (
                        <div className='common-window-item' key={`${n}-${item.id}`}>
                            <Link to={`/game/${item.id}`} state={{ from: true }}>
                                <CustomImage className='common-window-img' src={item.iconv}></CustomImage>
                                <p className='common-window-name'>{item.name}</p>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default memo(CommonWindow)