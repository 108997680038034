import './index.css'
import { useState, useContext } from 'react'
import Dialog from '../../components/dialog'
import AppContext from '../../common/context'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'
import { removeFromLocalStorage } from '../../common/util'
import { AuthCacheKey, UICacheKey, LastCheckCacheKey, NewGuyGiftCacheKey, FreeCardCacheKey } from '../../config'
import Auth from '../../components/auth'
import AuthResult from '../../components/auth/result'

function Setting({ close }) {
    const [showLogoutTip, toggleLogoutTip] = useState(false)
    const [showAuth, toggleAuth] = useState(false)
    const { user } = useContext(AppContext)

    const goToLogout = () => {
        toggleLogoutTip(true)
    }

    const cancelLogout = () => {
        toggleLogoutTip(false)
    }

    const logout = () => {
        removeFromLocalStorage(AuthCacheKey)
        removeFromLocalStorage(UICacheKey)
        removeFromLocalStorage(LastCheckCacheKey)
        removeFromLocalStorage(NewGuyGiftCacheKey)
        removeFromLocalStorage(FreeCardCacheKey)
        window.location.href = '/'
    }

    const goToAuth = () => {
        toggleAuth(true)
    }

    const closeAuth = () => {
        toggleAuth(false)
    }

    return (
        <>
            <motion.div className='app-page-container pt44' {...SlideFromRight}>
                <div className='app-page-header'>
                    <h1 className='app-page-header-title'>设置</h1>
                    <i className='app-page-header-back' onClick={close}></i>
                </div>
                <div className='app-setting-content-wrapper'>
                    <div className='app-setting-item'>
                        {
                            user.phone
                                ? <span className='app-setting-item-to value'>{user.phone}</span>
                                : <span className='app-setting-item-to'>去绑定</span>
                        }
                        <p className='app-setting-item-label'>手机号</p>
                    </div>
                    <div className='app-setting-item'>
                        <span className='app-setting-item-to'>去绑定</span>
                        <p className='app-setting-item-label'>微信号</p>
                    </div>
                    <div className='app-setting-item' onClick={goToAuth}>
                        {
                            user.realID
                                ? <span className='app-setting-item-to value'>已实名</span>
                                : <span className='app-setting-item-to'>去实名</span>
                        }
                        <p className='app-setting-item-label'>实名认证</p>
                    </div>
                    {/* <div className='app-setting-item'>
                        <span className='app-setting-item-to'>未开启</span>
                        <p className='app-setting-item-label'>青少年模式</p>
                    </div> */}
                    <div className='app-setting-item'>
                        <span className='app-setting-item-to value'>当前版本：V1.0.0</span>
                        <p className='app-setting-item-label'>检测更新</p>
                    </div>
                    {/* <div className='app-setting-item'>
                        <span className='app-setting-item-to'></span>
                        <p className='app-setting-item-label'>注销账号</p>
                    </div> */}
                </div>
                <div className='app-setting-logout-wrapper'>
                    <button className='app-setting-logout-btn' onClick={goToLogout}>退出当前账号</button>
                </div>
            </motion.div>
            {
                showLogoutTip && <Dialog className={'logout'} title={'温馨提示'} desc='确定退出登录吗？' okFunc={logout} cancelFunc={cancelLogout}></Dialog>
            }
            {
                showAuth && !!user.realID && <AuthResult close={closeAuth}></AuthResult>
            }
            {
                showAuth && !user.realID && <Auth close={closeAuth}></Auth>
            }
        </>
    )
}

export default Setting