import './index.css'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../common/context'
import CustomImage from '../../components/image'
import AppNav from '../../components/appNav'
import DefaultAvatar from '../../assets/uc/avatar.png'
import { Link } from 'react-router-dom'
import { getUserInfo } from '../../common/service'
import useNeedAuth from '../../hooks/useNeedAuth'
import { setToLocalStorage, getTime, getDay } from '../../common/util'
import { UICacheKey, LastCheckCacheKey } from '../../config'
import PubSub from 'pubsub-js'
import { EventTip, EventType } from '../../common/event'
import Property from '../property'
import { motion, AnimatePresence } from 'framer-motion'
import { getCheapPackage, getGameRecord } from './service'
import CDKEY from '../cdkey'
import Packet from '../packet'
import Apply from '../apply'
import Setting from '../setting'
import Report from '../report'
import About from '../about'
import Service from '../service'
// import AccountHelper from '../accountHelper'

const HiddenInput = 'app-user-card-uid-hidden'

function UserCenter() {
    const { isLogin, user, setUser, toggleLoginPage, sysConfig, userReadyState, setPayIndex, togglePay, toggleLvDetailFromVertical, toggleLvDetail, toggleSignIn, toggleSignInFromVertical, showSignIn } = useContext(AppContext)
    const [growthStyle, setGrowthStyle] = useState('0%')
    const [growthMax, setGrowthMax] = useState(0)
    const [showProperty, toggleProperty] = useState(false)
    const [showCDKEY, toggleCDKEY] = useState(false)
    const [cheap, setCheap] = useState('')
    const [playHistory, setPlayHistory] = useState([])
    const [showPacket, togglePacket] = useState(false)
    const [showApply, toggleApply] = useState(false)
    const [showSetting, toggleSetting] = useState(false)
    const [showReport, toggleReport] = useState(false)
    const [showAbout, toggleAbout] = useState(false)
    const [showService, toggleService] = useState(false)
    // const [showAccountHelper, toggleAccountHelper] = useState(false)
    useNeedAuth()

    useEffect(() => {
        if (isLogin) {
            getUserInfo().then(data => {
                setUser(data)
                setToLocalStorage(UICacheKey, data)
                setToLocalStorage(LastCheckCacheKey, Date.now() + '')
                getCheapPackage().then(p => {
                    p && setCheap(p.convertPrice + '')
                })
                getGameRecord().then(data => {
                    if (Array.isArray(data) && data.length) {
                        setPlayHistory(data)
                    }
                })
            }).catch(console.error)
        } else if (userReadyState) {
            toggleLoginPage(true)
        }
    }, [isLogin, userReadyState, setUser, toggleLoginPage])

    useEffect(() => {
        if (!user.lv || !user.growth || !Array.isArray(sysConfig.lvs)) {
            return setGrowthStyle('0%')
        }
        const lv = sysConfig.lvs.find(l => l.name === `lv${user.lv}`)
        if (!lv) {
            return setGrowthStyle('0%')
        }
        setGrowthMax(lv.max)
        setGrowthStyle(`${(user.growth * 100 / lv.max).toFixed(1)}%`)
    }, [user.lv, user.growth, sysConfig.lvs])

    const goToLogin = () => {
        !isLogin && toggleLoginPage(true)
    }

    const copyUid = () => {
        document.getElementById(HiddenInput).select()
        document.execCommand('copy')
        PubSub.publish(EventTip, { msg: '复制成功', type: EventType.Success })
    }

    const goToProperty = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleProperty(true)
    }

    const goToSignIn = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleSignInFromVertical(true)
        toggleSignIn(true)
    }

    const goToCDKEY = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleCDKEY(true)
    }

    const closeCDKEY = () => {
        toggleCDKEY(false)
    }

    const goToPacket = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        togglePacket(true)
    }

    const closePacket = () => {
        togglePacket(false)
    }

    const goToApply = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleApply(true)
    }

    const closeApply = () => {
        toggleApply(false)
    }

    const goToSetting = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleSetting(true)
    }

    const closeSetting = () => {
        toggleSetting(false)
    }

    const goToReport = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleReport(true)
    }

    const closeReport = () => {
        toggleReport(false)
    }

    const goToBuyTime = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        setPayIndex(1)
        togglePay(true)
    }

    const goToBuyVip = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        setPayIndex(0)
        togglePay(true)
    }

    const goToAbout = () => {
        toggleAbout(true)
    }

    const closeAbout = () => {
        toggleAbout(false)
    }

    const goToLvDetail = () => {
        if (isLogin) {
            toggleLvDetailFromVertical(true)
            toggleLvDetail(true)
        }
    }

    const goToService = () => {
        toggleService(true)
    }

    const closeService = () => {
        toggleService(false)
    }

    // const goToAccountHelper = () => {
    //     if (!isLogin) {
    //         return toggleLoginPage(true)
    //     }
    //     toggleAccountHelper(true)
    // }

    // const closeAccountHelper = () => {
    //     toggleAccountHelper(false)
    // }

    const isVip = user.role === 1
    const showChild = showProperty || showSignIn || showCDKEY || showPacket || showApply || showReport || showAbout || showSetting || showService// || showAccountHelper

    return (
        <>
            <motion.div className='app-page-container' transition={{ type: 'tween' }} animate={{ x: showChild ? '-25vw' : '0vw' }}>
                <div className='app-page-window grey'>
                    <div className='app-user-center-wrapper'>
                        <div className='app-user-center-header'>
                            <div className='app-user-center-service-wrapper'>
                                <i className='app-user-center-service' onClick={goToService}></i>
                            </div>
                        </div>
                        <div onClick={goToLogin}>
                            <div className='app-user-card-upper'>
                                <div className='app-user-card-avatar-wrapper'>
                                    <CustomImage className='app-user-card-avatar' src={isLogin && user.avatar ? user.avatar : DefaultAvatar}></CustomImage>
                                </div>
                                <p className='app-user-card-name'>{isLogin ? user.nickName : '未登录'}</p>
                                {
                                    isLogin && <>
                                        <div className='app-user-card-medal-line'>
                                            {
                                                user.realID
                                                    ? <div className='app-user-card-medal-auth'>已实名</div>
                                                    : <div className='app-user-card-medal-auth not'>未实名</div>
                                            }
                                            <div className='app-user-card-medal-lv' onClick={goToLvDetail}>LV{user.lv}</div>
                                            {
                                                isVip && <i className='app-user-card-medal-vip'></i>
                                            }
                                        </div>
                                        <p className='app-user-card-uid'>ID: {user.uid}<i className='app-user-card-uid-copy' onClick={copyUid}></i></p>
                                    </>
                                }
                                <div className='app-user-card-uid-hidden-wrapper'>
                                    <textarea className={HiddenInput} id={HiddenInput} value={user.uid || ''} readOnly></textarea>
                                </div>
                            </div>
                            <div className='app-user-progress-wrapper'>
                                <div className='app-user-progress' style={{ width: growthStyle }}></div>
                            </div>
                            <div className='app-user-grow-wrapper' onClick={goToLvDetail}>
                                {
                                    isLogin && <>
                                        <div className='app-user-grow-text-wrapper'>
                                            成长值: <span className='app-user-grow-text-value'>{user.growth}</span>/{growthMax}
                                        </div>
                                        <div className='app-user-grow-vip-tip-wrapper'>
                                            {
                                                isVip
                                                    ? <div className='app-user-grow-vip-tip valid'>会员双倍中</div>
                                                    : <div className='app-user-grow-vip-tip'>开通会员加速</div>
                                            }
                                        </div>
                                        <p className='app-user-grow-queue-tip'>当前等级为您节省<span className='app-user-grow-queue-tip-value'>{Math.round(user.vipSpeed * 100)}%</span>排队等待时长</p>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='app-user-right-wrapper'>
                            <div className='app-user-right-time' onClick={goToBuyTime}>
                                {
                                    !!cheap && <div className='app-user-right-float'>低至{cheap}元/小时</div>
                                }
                            </div>
                            <div className={isVip ? 'app-user-right-vip valid' : 'app-user-right-vip'} onClick={goToBuyVip}>
                                <div className='app-user-right-float'>开通每日赠时长</div>
                            </div>
                        </div>
                        <div className='app-user-property-wrapper' onClick={goToProperty}>
                            <p className='app-user-block-title'>我的资产<i className='app-user-block-title-link'></i></p>
                            <ul className='app-user-property-ul'>
                                <li className='app-user-property-block'>
                                    <p className='app-user-property-block-value'>{isVip ? getDay(user.vipTime) : 0}</p>
                                    <p className='app-user-property-block-desc'>VIP有效期(天)</p>
                                </li>
                                <li className='app-user-property-split'></li>
                                <li className='app-user-property-block'>
                                    <p className='app-user-property-block-value'>{getTime(user.peakDuration + user.tempDuration)}</p>
                                    <p className='app-user-property-block-desc'>游戏高峰时长</p>
                                </li>
                            </ul>
                        </div>
                        {
                            playHistory.length > 0 && <div className='app-user-play-history-wrapper'>
                                <p className='app-user-block-title'>最近玩过</p>
                                <div className='app-user-play-history-window-wrapper'>
                                    <div className='app-user-play-history-window'>
                                        <div className='app-user-play-history-ul'>
                                            {
                                                playHistory.map(item => (
                                                    <Link className='app-user-play-history-item' key={item.id} to={`/game/${item.id}`} state={{ from: true }}>
                                                        <div className={item.status === 1 ? 'app-user-play-history-item-img-wrapper' : 'app-user-play-history-item-img-wrapper disabled'}>
                                                            <CustomImage className='app-user-play-history-item-img' src={item.iconv}></CustomImage>
                                                        </div>
                                                        <p className='app-user-play-history-item-name'>{item.name}</p>
                                                    </Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='app-user-navs-wrapper'>
                            <p className='app-user-block-title'>常用功能</p>
                            <ul className='app-user-navs-ul'>
                                <li className='app-user-nav-item' onClick={goToSignIn}>
                                    <i className='app-user-nav-item-icon sign'></i>
                                    <p className='app-user-nav-item-name'>签到</p>
                                </li>
                                <li className='app-user-nav-item' onClick={goToCDKEY}>
                                    <i className='app-user-nav-item-icon invite'></i>
                                    <p className='app-user-nav-item-name'>邀请有礼</p>
                                </li>
                                <li className='app-user-nav-item' onClick={goToPacket}>
                                    <i className='app-user-nav-item-icon red'></i>
                                    <p className='app-user-nav-item-name'>口令红包</p>
                                </li>
                                {/* <li className='app-user-nav-item' onClick={goToAccountHelper}>
                                    <i className='app-user-nav-item-icon game'></i>
                                    <p className='app-user-nav-item-name'>上号助手</p>
                                </li> */}
                                <li className='app-user-nav-item' onClick={goToApply}>
                                    <i className='app-user-nav-item-icon like'></i>
                                    <p className='app-user-nav-item-name'>游戏心愿单</p>
                                </li>
                            </ul>
                            <ul className='app-user-navs-ul'>
                                <li className='app-user-nav-item' onClick={goToReport}>
                                    <i className='app-user-nav-item-icon question'></i>
                                    <p className='app-user-nav-item-name'>问题反馈</p>
                                </li>
                                <li className='app-user-nav-item' onClick={goToAbout}>
                                    <i className='app-user-nav-item-icon about'></i>
                                    <p className='app-user-nav-item-name'>关于我们</p>
                                </li>
                                <li className='app-user-nav-item' onClick={goToSetting}>
                                    <i className='app-user-nav-item-icon set'></i>
                                    <p className='app-user-nav-item-name'>设置</p>
                                </li>
                                <li className='app-user-nav-item'></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </motion.div>
            <AppNav index={1}></AppNav>
            <AnimatePresence>
                {
                    showProperty && <Property toggleShowState={toggleProperty}></Property>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showCDKEY && <CDKEY close={closeCDKEY}></CDKEY>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showPacket && <Packet close={closePacket}></Packet>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showApply && <Apply close={closeApply}></Apply>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showSetting && <Setting close={closeSetting}></Setting>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showReport && <Report close={closeReport}></Report>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showAbout && <About close={closeAbout}></About>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showService && <Service close={closeService} fromUC={true}></Service>
                }
            </AnimatePresence>
            {/* <AnimatePresence>
                {
                    showAccountHelper && <AccountHelper close={closeAccountHelper}></AccountHelper>
                }
            </AnimatePresence> */}
        </>
    )
}

export default UserCenter