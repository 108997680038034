import './indexHeader.css'
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { lerp } from '../../common/util'
import AppContext from '../../common/context'

const DefaultBackgroundColor = '#fff'
const DefaultColor = '#121310'

function IndexHeader({ index, setIndex }) {
    const [backgroundColor, setBackgroundColor] = useState('rgba(255,255,255,0)')
    const [color, setColor] = useState('rgb(255,255,255)')
    const { isLogin, toggleLoginPage, toggleSignIn, toggleSignInFromVertical } = useContext(AppContext)

    useEffect(() => {
        if (index === 0) {
            const el = document.getElementById('app-index-window')
            const { height } = el.getBoundingClientRect()
            const listener = () => {
                const { scrollTop } = el
                let sp = Math.round(scrollTop * 3 * 100 / height) / 100
                sp > 1 && (sp = 1)
                setBackgroundColor(`rgba(255,255,255,${sp})`)
                setColor(`rgb(${lerp(255, 18, sp)},${lerp(255, 19, sp)},${lerp(255, 16, sp)})`)
            }
            listener()
            el.addEventListener('scroll', listener)
            return () => {
                el.removeEventListener('scroll', listener)
            }
        } else {
            setBackgroundColor(DefaultBackgroundColor)
            setColor(DefaultColor)
        }
    }, [index])

    const goToSignin = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        toggleSignInFromVertical(false)
        toggleSignIn(true)
    }

    return (
        <div className='app-index-header' style={{ backgroundColor, color }}>
            <div className={index === 0 ? 'app-index-header-item active' : 'app-index-header-item'} onClick={() => setIndex(0)}>推荐</div>
            <div className={index === 1 ? 'app-index-header-item active' : 'app-index-header-item'} onClick={() => setIndex(1)}>游戏库</div>
            <div className={index === 2 ? 'app-index-header-item active' : 'app-index-header-item'} onClick={() => setIndex(2)}>收藏</div>
            <Link to={'/search'} style={{ marginLeft: 'auto' }}>
                <div className='app-index-header-search' style={{ backgroundColor: color }}></div>
            </Link>
            <div className='app-index-header-signin' onClick={goToSignin}></div>
        </div>
    )
}

export default IndexHeader