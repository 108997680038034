import './index.css'
import { getRecordHistory, doSignIn } from './service'
import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import PubSub from 'pubsub-js'
import { EventUserInvalid, EventTip, EventRefreshUser } from '../../common/event'
import AppContext from '../../common/context'
import { getDayOfWeek, getTime, getRem, getTimeTxt } from '../../common/util'
import { motion } from 'framer-motion'
import { SlideFromBottom, SlideFromRight } from '../../animation'

// const freeCardTime = () => {
//     const now = new Date()
//     const day = now.getDay() || 7
//     if (day > 5) {
//         return false
//     } else if (day === 5 && now.getHours() >= 18) {
//         return false
//     }
//     return true
// }

function SignIn() {
    const [signedIn, setSignInState] = useState(-1)
    const [todayDuration, setTodayDuration] = useState(0)
    const [record, setRecord] = useState([])
    const [index] = useState(getDayOfWeek())
    const [showSignInTip, toggleSignInTip] = useState(false)
    const [signAsVip, isSignAsVip] = useState(false)
    const [showSignInResult, toggleSignInResult] = useState(false)
    const { user, setPayIndex, togglePay, sysConfig, toggleFreeCard, toggleFreeCardEntry, setFreeCardList, toggleSignIn, signInFromVertical } = useContext(AppContext)
    const submitFlag = useRef(false)
    const scrollFlag = useRef(false)
    const close = useCallback(() => {
        toggleSignIn(false)
    }, [toggleSignIn])

    useEffect(() => {
        getRecordHistory().then(data => {
            const { def, vip } = data
            const week = def.map((d, n) => ({ normal: d, vip: vip[n], index: n + 1 }))
            setRecord(week)
            const today = week.find(d => d.index === index)
            if (today.vip.timestamp || today.normal.timestamp) {
                setSignInState(1)
                setTodayDuration(today.vip.duration + today.normal.duration)
            } else {
                setSignInState(0)
            }
        }).catch(e => {
            setSignInState(0)
            PubSub.publish(EventTip, { msg: e.message })
        })
    }, [index])

    useEffect(() => {
        if (record.length && index > 2 && !scrollFlag.current) {
            const el = document.getElementById('app-signin-content-window')
            el.scrollLeft = ((index - 0.5) * 0.7 - (0.5 * 3.05)) * getRem()
            scrollFlag.current = true
        }
    }, [record, index])

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [close])

    const signin = () => {
        if (user.role !== 1) {
            return toggleSignInTip(true)
        }
        submit()
    }

    const submit = () => {
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        doSignIn().then(data => {
            const { duration, durationToVip } = data
            setSignInState(1)
            setTodayDuration(duration + durationToVip)
            toggleSignInTip(false)
            isSignAsVip(durationToVip > 0)
            toggleSignInResult(true)
            const newRecord = record.slice()
            const todayRecord = { ...(newRecord[index - 1]) }
            const timestamp = Date.now()
            todayRecord.normal.timestamp = timestamp
            durationToVip > 0 && (todayRecord.vip.timestamp = timestamp)
            newRecord[index - 1] = todayRecord
            setRecord(newRecord)
            PubSub.publish(EventRefreshUser)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const closeSignInVipTip = () => {
        toggleSignInTip(false)
    }

    const closeSignInResult = () => {
        toggleSignInResult(false)
    }

    const goToBuyVip = () => {
        setPayIndex(0)
        togglePay(true)
        closeSignInVipTip()
        closeSignInResult()
    }

    const goToFreeCard = () => {
        toggleFreeCardEntry(false)
        setFreeCardList([])
        toggleFreeCard(true)
    }

    const showFreeCard = sysConfig.showTimeCard // && freeCardTime()

    return (
        <motion.div className='app-page-container dialog zIndex5' {...(signInFromVertical ? SlideFromRight : SlideFromBottom)}>
            <div className='app-signin-container'>
                <div className='app-signin-header'>
                    <i className='app-signin-close' onClick={close}></i>
                </div>
                <div className='app-page-window'>
                    <div className='app-signin-today-wrapper'>
                        <p className='app-signin-today-tip'>今日获取高峰时长<span className='app-signin-today-tip-date'>(当日24点失效)</span></p>
                        <p className='app-signin-today-value'>{getTime(todayDuration)}</p>
                    </div>
                    <div className='app-signin-main-wrapper'>
                        <div className='app-signin-type vip'></div>
                        <div className='app-signin-type normal'></div>
                        <div className='app-signin-content-window' id='app-signin-content-window'>
                            <div className='app-signin-content'>
                                {
                                    record.map(item => (
                                        <div className={index === item.index
                                            ? 'app-signin-content-item active'
                                            : index > item.index
                                                ? 'app-signin-content-item disabled'
                                                : 'app-signin-content-item'} key={item.index}>
                                            <div className='app-signin-vip-block'>
                                                <p className='app-signin-day'>{item.index}</p>
                                                <i className='app-signin-clock'></i>
                                                {
                                                    item.vip.timestamp
                                                        ? <i className='app-signin-checked'></i>
                                                        : <p className='app-signin-vip-prize'>1小时</p>
                                                }
                                                {
                                                    !item.vip.timestamp && user.role === 0 && <i className='app-signin-vip-lock-icon'></i>
                                                }
                                            </div>
                                            <div className='app-signin-normal-block'>
                                                <i className='app-signin-clock'></i>
                                                <p className='app-signin-normal-prize'>{item.normal.timestamp ? '已领取' : '随机'}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {
                            signedIn === 0 && <button className='app-signin-main-btn' onClick={signin}></button>
                        }
                        {
                            signedIn === 1 && <button className='app-signin-main-btn disabled'></button>
                        }
                    </div>
                    {
                        showFreeCard && <div className='app-signin-freecard-wrapper' onClick={goToFreeCard}></div>
                    }
                    <div className={showFreeCard ? 'app-signin-vip-tip-header show-free-card' : 'app-signin-vip-tip-header'}></div>
                    <div className='app-signin-vip-tip-wrapper'>
                        <div className='app-signin-vip-tip-item first-line'>
                            <p className='app-signin-vip-tip-txt'>VIP专属签到时长</p>
                        </div>
                        <div className='app-signin-vip-tip-item first-line'>
                            <p className='app-signin-vip-tip-txt first-line'>VIP双倍成长值</p>
                            <p className='app-signin-vip-tip-desc'>首个会员期间3倍</p>
                        </div>
                        <div className='app-signin-vip-tip-item'>
                            <p className='app-signin-vip-tip-txt'>全游戏自定义按键</p>
                        </div>
                        <div className='app-signin-vip-tip-item'>
                            <p className='app-signin-vip-tip-txt'>VIP排队加速</p>
                        </div>
                        <div className='app-signin-vip-tip-item'>
                            <p className='app-signin-vip-tip-txt'>全游戏蓝光画质</p>
                        </div>
                        <div className='app-signin-vip-tip-item'>
                            <p className='app-signin-vip-tip-txt'>购买时长赠送时长</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                showSignInTip && <div className='app-page-container dialog nest'>
                    <div className='app-signin-dialog-wrapper'>
                        <i className='app-signin-dialog-vip-icon'></i>
                        <i className='app-signin-dialog-close' onClick={closeSignInVipTip}></i>
                        <div className='app-signin-dialog-vip-title'>开通会员</div>
                        <p className='app-signin-dialog-vip-tip'>每日多领<span className='highlight'>1小时高峰时长</span></p>
                        <div className='app-signin-dialog-btn-wrapper'>
                            {
                                user.lv > 1
                                    ? <button className='app-signin-dialog-btn normal' onClick={submit}>普通签到</button>
                                    : <button className='app-signin-dialog-btn disabled'>普通签到</button>
                            }
                            <button className='app-signin-dialog-btn' onClick={goToBuyVip}>会员签到</button>
                        </div>
                    </div>
                </div>
            }
            {
                showSignInResult && <div className='app-page-container dialog nest'>
                    <div className='app-signin-dialog-wrapper result'>
                        <div className='app-signin-dialog-vip-title'>签到成功</div>
                        <div className='app-signin-result'>
                            <p className='app-signin-result-type'>{signAsVip ? '会员+' : ''}普通赠送</p>
                            <p className='app-signin-result-value'>{getTimeTxt(todayDuration)}</p>
                        </div>
                        <div className='app-signin-result-tip'>赠送时长当日有效</div>
                        {
                            signAsVip
                                ? <button className='app-signin-result-btn' onClick={closeSignInResult}>知道了</button>
                                : <button className='app-signin-result-btn for-vip' onClick={goToBuyVip}>会员签到领更多</button>
                        }
                        <i className='app-signin-dialog-close' onClick={closeSignInResult}></i>
                    </div>
                </div>
            }
        </motion.div>
    )
}

export default SignIn