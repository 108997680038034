import './index.css'
import './sms.css'
import { useState, useRef, useEffect, useContext } from 'react'
// import { useParams } from 'react-router-dom'
import { loginBySMS, sendSMSCode, bindPhoneByService } from './service'
import PubSub from 'pubsub-js'
import { EventTip, EventType, EventLoginCallback } from '../../common/event'
import AppContext from '../../common/context'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'

const TimeInterval = 60
const SMSLength = 6
const SMSReg = new RegExp(`^\\d{1,${SMSLength}}$`)
const ValidSMSReg = new RegExp(`^\\d{${SMSLength}}$`)

function Sms() {
    const { loginPhone, toggleLoginPage, toggleSmsPage, bindPhone, toggleBindPhone } = useContext(AppContext)
    const [valid, toggleValid] = useState(false)
    const [helpTxt, setHelpTxt] = useState('')
    const [inputSms, setSms] = useState('')
    const [complete, toggleCompleteState] = useState(false)
    // const { phone } = useParams()
    const submitFlag = useRef(false)
    const sendFlag = useRef(false)
    const timer = useRef(null)
    // const navigate = useNavigate()

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearInterval(timer.current)
            }
        }
    }, [])

    useEffect(() => {
        if (!valid) {
            let time = TimeInterval
            setHelpTxt(`${time}s`)
            timer.current = setInterval(() => {
                --time
                if (time > 0) {
                    setHelpTxt(`${time}s`)
                } else {
                    clearInterval(timer.current)
                    timer.current = null
                    toggleValid(true)
                }
            }, 1000)
        }
    }, [valid])

    useEffect(() => {
        if (complete) {
            toggleSmsPage(false)
            toggleLoginPage(false)
        }
    }, [complete, toggleSmsPage, toggleLoginPage])

    const input = e => {
        if (submitFlag.current) {
            return
        }
        const { value } = e.target
        if (!value) {
            return setSms(value)
        }
        let trimValue = value.replace(/\s/g, '')
        if (!SMSReg.test(trimValue)) {
            return
        }
        setSms(trimValue)
        if (ValidSMSReg.test(trimValue)) {
            submitFlag.current = true
            const timestamp = Date.now()
            if (bindPhone) {
                bindPhoneByService(window._temp_wechat_at, loginPhone, trimValue).then(data => {
                    PubSub.publish(EventLoginCallback, {
                        userInfo: data,
                        token: window._temp_wechat_at,
                        freeDuration: window._temp_wechat_fd
                    })
                    toggleCompleteState(true)
                }).catch(e => {
                    submitFlag.current = false
                    if (e.code === 401) {
                        toggleBindPhone(false)
                        toggleSmsPage(false)
                        PubSub.publish(EventTip, { msg: '授权已失效，请重新登录' })
                    } else {
                        PubSub.publish(EventTip, { msg: e.message })
                    }
                })
            } else {
                loginBySMS(loginPhone, trimValue, timestamp).then(data => {
                    PubSub.publish(EventLoginCallback, data)
                    toggleCompleteState(true)
                }).catch(e => {
                    submitFlag.current = false
                    PubSub.publish(EventTip, { msg: e.message })
                })
            }
        }
    }

    const sendSMS = () => {
        if (sendFlag.current) {
            return
        }
        sendFlag.current = true
        sendSMSCode(loginPhone).then(() => {
            toggleValid(false)
            sendFlag.current = false
            PubSub.publish(EventTip, { msg: '发送成功', type: EventType.Success })
        }).catch(e => {
            sendFlag.current = false
            PubSub.publish(EventTip, { msg: e.message })
        })
    }

    const goBack = () => {
        // navigate(-1)
        toggleSmsPage(false)
    }

    const motionProp = complete ? { ...SlideFromRight, exit: { y: '100vh' } } : SlideFromRight

    return (
        <motion.div className='app-page-container zIndex3' {...motionProp}>
            <div className='app-page-window'>
                <div className='app-whole-page app-login-wrapper'>
                    <div className='app-login-header'>
                        <i className='app-login-header-close back' onClick={goBack}></i>
                    </div>
                    <div className='app-login-main-content'>
                        <p className='app-login-phone-title'>
                            <i className='app-login-phone-icon sms'></i>
                            输入{SMSLength}位验证码
                        </p>
                        <p className='app-login-phone-tip'>验证码已发送至+86 {loginPhone}</p>
                        <div className='app-login-phone-input-wrapper'>
                            <input className='app-login-phone-input' placeholder='请输入验证码' value={inputSms} onChange={input}></input>
                            {
                                valid ? <div className='app-login-sms-btn' onClick={sendSMS}>获取验证码</div> : <div className='app-login-sms-cd'>{helpTxt}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Sms