import { queryVipOrder, queryTimeOrder, queryNewGuyGiftOrder, queryFreeCardOrder } from './service'
import PubSub from 'pubsub-js'
import { useEffect, useRef, useState } from 'react'
import { EventPayStart, EventPayEnd, EventRefreshUser, EventTip, EventType } from '../../common/event'

const RetryTime = 50
const RetryInterval = 2500
const toPromise = task => {
    if (task.type === 0) {
        return queryVipOrder(task.orderid).catch(() => ({}))
    }
    if (task.type === 1) {
        return queryTimeOrder(task.orderid).catch(() => ({}))
    }
    if (task.type === 2) {
        return queryNewGuyGiftOrder(task.orderid).catch(() => ({}))
    }
    if (task.type === 3) {
        return queryFreeCardOrder(task.orderid).catch(() => ({}))
    }
    return Promise.resolve({ status: 1 })
}

function PayCallback() {
    const [refreshHelper, setRefreshHelper] = useState(0)
    const taskList = useRef([])
    const timer = useRef(null)

    useEffect(() => {
        const listener = PubSub.subscribe(EventPayStart, (_, { type, orderid }) => {
            taskList.current.push({ type, orderid, time: RetryTime })
            setRefreshHelper(h => h + 1)
        })

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [])

    useEffect(() => {
        if (timer.current) {
            return
        }
        if (taskList.current.length) {
            const prepared = taskList.current.map(t => ({ ...t, time: t.time - 1 }))
            taskList.current = []
            timer.current = setTimeout(() => {
                Promise.all(prepared.map(toPromise)).then(rets => {
                    for (let i = 0; i < prepared.length; ++i) {
                        if (rets[i].status === 1) {
                            // togglePay(false)
                            PubSub.publish(EventRefreshUser)
                            PubSub.publish(EventPayEnd)
                            PubSub.publish(EventTip, { msg: '购买成功', type: EventType.Success })
                        } else if (prepared[i].time > 0) {
                            taskList.current.push(prepared[i])
                        }
                    }
                    timer.current = null
                    setRefreshHelper(h => h + 1)
                })
            }, RetryInterval)
        }
    }, [refreshHelper])

    return null
}

export default PayCallback