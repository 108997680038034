import Http, { HackHttp } from '../../common/request'
import { AppKey as _appKey, ClientType as _clientType, AppVersion as _version } from '../../config'

export const sendSMSCode = (phone) => {
    return Http('/api/client/sendSMScode', { phone })
}

export const loginBySMS = (phone, code, _timestamp) => {
    return Http('/api/client/login', {
        phone,
        code,
        _timestamp,
        _appKey,
        _clientType,
        _version
    })
}

export const bindPhoneByService = (token, phone, code) => {
    return HackHttp('/api/client/auth/bindPhone', token, { phone, code })
}