import './index.css'
import { memo, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomImage from '../image'
import { getGameRank } from './service'

function Rank({ isGrid = false }) {
    const [index, setIndex] = useState(0)
    const [hotRank, setHotRank] = useState([])
    const [newRank, setNewRank] = useState([])
    const loadHotFlag = useRef(false)
    const loadNewFlag = useRef(false)
    const windowEl = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (index === 0 && !loadHotFlag.current) {
            loadHotFlag.current = true
            getGameRank(index).then(data => {
                setHotRank(data)
            })
        }
        if (index === 1 && !loadNewFlag.current) {
            loadNewFlag.current = true
            getGameRank(index).then(data => {
                setNewRank(data)
            })
        }
        isGrid && windowEl.current && (windowEl.current.scrollLeft = 0)
        !isGrid && windowEl.current && (windowEl.current.scrollTop = 0)
    }, [index, isGrid])

    const goToDetail = gameid => {
        navigate(`/game/${gameid}`, { state: { from: true } })
    }

    return (
        <div className={isGrid ? '' : 'rank-page-content'}>
            <div className='rank-header'>
                <div className={index === 0 ? 'rank-header-item active' : 'rank-header-item'} onClick={() => setIndex(0)}>热游榜</div>
                <div className={index === 1 ? 'rank-header-item active' : 'rank-header-item'} onClick={() => setIndex(1)}>新游榜</div>
            </div>
            <div className={isGrid ? 'rank-content-grid-window' : 'app-page-window'} ref={windowEl}>
                <div className={isGrid ? 'rank-content-grid-ul' : ''}>
                    {
                        (index === 0 ? hotRank : newRank).map((item, n) => (
                            <div className={`rank-content-item index-${n}`} key={`${n}-${item.id}`} onClick={() => goToDetail(item.id)}>
                                <div className='rank-item-n'>{n + 1}</div>
                                <div className='rank-item-img-wrapper'>
                                    <CustomImage className='rank-item-img' src={item.iconh}></CustomImage>
                                </div>
                                <div className='rank-item-info'>
                                    <p className='rank-item-name'>{item.name}</p>
                                    <p className='rank-item-desc'>{item.introduction}</p>
                                    <p className='rank-item-tags'>{item.tag.map(t => t.name).join('·')}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(Rank)