import './index.css'
import { useState, useRef, useContext } from 'react'
import AppContext from '../../common/context'
import { authByService } from './service'
import PubSub from 'pubsub-js'
import { EventTip, EventType, EventRefreshUser } from '../../common/event'

function Auth({ close }) {
    const { setAgreementType, toggleAgreement } = useContext(AppContext)
    const [realName, setRealName] = useState('')
    const [idCard, setIdCard] = useState('')
    const [agree, toggleAgree] = useState(false)
    const submitFlag = useRef(false)

    const inputRealName = e => {
        const { value } = e.target
        setRealName(value)
    }

    const resetRealName = () => {
        setRealName('')
    }

    const inputIdCard = e => {
        const { value } = e.target
        setIdCard(value)
    }

    const resetIdCard = () => {
        setIdCard('')
    }

    const doToggleAgree = () => {
        toggleAgree(a => !a)
    }

    const invalid = !(realName || '').trim() || !(idCard || '').trim() || !agree

    const submit = () => {
        if (invalid || submitFlag.current) {
            return
        }
        submitFlag.current = true
        authByService(idCard, realName).then(() => {
            PubSub.publish(EventTip, { msg: '认证成功', type: EventType.Success })
            PubSub.publish(EventRefreshUser)
            close()
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const goToPrivacyAgreement = () => {
        setAgreementType(2)
        toggleAgreement(true)
    }

    return (
        <div className='app-page-container dialog'>
            <div className='app-auth-container'>
                <p className='app-auth-title'>实名认证</p>
                <i className='app-auth-close' onClick={close}></i>
                <p className='app-auth-desc'>
                    <span>根据法律法规的要求，在实名认证过程中，我们和提供认证服务的第三方机构需要您提供真实姓名、身份证号码。</span>
                    <span>这些信息是个人敏感信息，平台会对信息进行严格保密，未经您明示授权，不会用作其他目的。</span>
                </p>
                <div className='app-auth-form-line'>
                    <input className='app-auth-form-input' placeholder='请输入真实姓名' value={realName} onChange={inputRealName}></input>
                    {
                        !!(realName || '').trim() && <i className='app-auth-form-reset' onClick={resetRealName}></i>
                    }
                </div>
                <div className='app-auth-form-line'>
                    <input className='app-auth-form-input' placeholder='请输入您的身份证号' value={idCard} onChange={inputIdCard}></input>
                    {
                        !!(idCard || '').trim() && <i className='app-auth-form-reset' onClick={resetIdCard}></i>
                    }
                </div>
                <div className='app-auth-btn-wrapper'>
                    <button className={invalid ? 'app-auth-btn disabled' : 'app-auth-btn'} onClick={submit}>同意协议并认证</button>
                </div>
                <p className='app-auth-agreement'><i className={agree ? 'checkbox checked' : 'checkbox'} onClick={doToggleAgree}></i><span onClick={doToggleAgree}>已阅读并同意</span><span className='highlight' onClick={goToPrivacyAgreement}>《隐私政策》</span></p>
            </div>
        </div>
    )
}

export default Auth