import { getServiceUrl } from './service'
import { SlideFromBottom, SlideFromRight } from '../../animation'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { ServiceLink } from '../../config'

function Service({ fromUC = false, close }) {
    const [frameSrc, setFrameSrc] = useState('')

    useEffect(() => {
        getServiceUrl().then(data => {
            setFrameSrc(`${ServiceLink}&_user_access_token=${data.Data}`)
        }).catch(e => {
            console.error(e)
            setFrameSrc(ServiceLink)
        })
    }, [])

    return (
        <motion.div className="app-page-container pt44" {...(fromUC ? SlideFromRight : SlideFromBottom)}>
            <div className='app-page-header'>
                <p className='app-page-header-title'>客服中心</p>
                <i className='app-page-header-back' onClick={close}></i>
            </div>
            {
                !!frameSrc && <iframe src={frameSrc} title='客服中心' className='app-frame-page'></iframe>
            }
        </motion.div>
    )
}

export default Service