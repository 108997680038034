import PubSub from "pubsub-js";
import { useEffect, useContext } from "react";
import { EventLogin, EventUserInvalid } from "../common/event";
import AppContext from "../common/context";
import { AuthCacheKey, UICacheKey, LastCheckCacheKey } from '../config'
import { removeFromLocalStorage } from "../common/util";

function useNeedAuth() {
    const { toggleLoginPage, toggleLoginState, setUser } = useContext(AppContext)

    useEffect(() => {
        const loginListener = PubSub.subscribe(EventLogin, () => {
            toggleLoginPage(true)
        })
        const invalidListener = PubSub.subscribe(EventUserInvalid, () => {
            toggleLoginState(false)
            setUser({})
            removeFromLocalStorage(AuthCacheKey)
            removeFromLocalStorage(UICacheKey)
            removeFromLocalStorage(LastCheckCacheKey)
        })

        return () => {
            PubSub.unsubscribe(loginListener)
            PubSub.unsubscribe(invalidListener)
        }
    }, [toggleLoginPage, toggleLoginState, setUser])
}

export default useNeedAuth