import Http from "../../common/request";
import { safeParse, getFromLocalStorage, setToLocalStorage } from "../../common/util";
import { GameSettingCacheKey } from "../../config";

export const addPlayTime = (cid, gameid) => {
    return Http('/api/client/auth/game/add/playingTime', { auth: true, cid, gameid })
}

export const getGameSetting = (gameid, isVip) => {
    const saved = safeParse(getFromLocalStorage(`${GameSettingCacheKey}${gameid}`))
    if (!isVip) {
        if (saved.opmethod === 2) { delete saved.opmethod }
        if (Number(saved.bitrate) > 1000) { delete saved.bitrate }
    }
    return saved
}

export const saveGameSetting = (gameid, prop) => {
    const saved = safeParse(getFromLocalStorage(`${GameSettingCacheKey}${gameid}`))
    setToLocalStorage(`${GameSettingCacheKey}${gameid}`, { ...saved, ...prop })
}