export const EventTip = 'EventTip'
export const EventType = {
    Error: 'error',
    Success: 'success',
    Game: 'game',
    GameTime: 'gameTime'
}
export const EventLogin = 'EventLogin'
export const EventLoginCallback = 'EventLoginCallback'
export const EventLoginEffect = 'EventLoginEffect'
export const EventUserInvalid = 'EventUserInvalid'
export const AuthorityErrorFlag = 'ERROR_NOT_AUTHORITY'
export const EventGameOver = 'EventGameOver'
export const EventGameStart = 'EventGameStart'
export const EventQualityList = 'EventQualityList'
export const EventRefreshUser = 'EventRefreshUser'
export const EventPayStart = 'EventPayStart'
export const EventPayEnd = 'EventPayEnd'
export const EventNoOperation = 'EventNoOperation'