import './index.css'
import { QQGroup } from '../../config'
import PubSub from 'pubsub-js'
import { EventTip, EventType } from '../../common/event'
import CustomImage from '../image'
import QQImg from './assets/qq.png'
import WechatImg from './assets/wechat.jpg'

const HiddenInput = 'group-dialog-hidden'

function GroupDialog({ close }) {

    const copyQQGroup = () => {
        document.getElementById(HiddenInput).select()
        document.execCommand('copy')
        PubSub.publish(EventTip, { msg: '复制成功', type: EventType.Success })
    }

    return (
        <div className='app-page-container dialog nest'>
            <div className='group-dialog-container'>
                <i className='group-dialog-line'></i>
                <i className='group-dialog-close' onClick={close}></i>
                <div className='group-dialog-qrcode'>
                    <CustomImage className='group-dialog-img' internal={true} src={QQImg}></CustomImage>
                </div>
                <div className='group-dialog-info'>
                    <span className='group-dialog-tip'>QQ群号：{QQGroup}</span>
                    <button className='group-dialog-btn' onClick={copyQQGroup}>复制</button>
                    <textarea className={HiddenInput} id={HiddenInput} value={QQGroup} readOnly></textarea>
                </div>
                <div className='group-dialog-qrcode bottom'>
                    <CustomImage className='group-dialog-img' internal={true} src={WechatImg}></CustomImage>
                </div>
                <div className='group-dialog-info bottom'>
                    <span className='group-dialog-tip'>微信搜索【超机云游戏】关注公众号</span>
                </div>
            </div>
        </div>
    )
}

export default GroupDialog