import Http from "../../common/request";

export const getSpeedUpMap = () => {
    return Http('/api/public/lvSpeed')
}

export const getCheapPackage = () => {
    return Http('/api/client/auth/timeList/lowest', { auth: true })
}

export const getGameRecord = () => {
    return Http('/api/client/auth/game/history', { auth: true })
}