import PubSub from "pubsub-js";
import { useEffect } from "react";
import { setToLocalStorage } from "../common/util";
import { UICacheKey, LastCheckCacheKey } from "../config";
import { EventRefreshUser } from "../common/event";
import { getUserInfo } from "../common/service";

function useRefreshUser(setUser) {
    useEffect(() => {
        const refreshListener = PubSub.subscribe(EventRefreshUser, () => {
            getUserInfo().then(data => {
                setUser(data)
                setToLocalStorage(UICacheKey, data)
                setToLocalStorage(LastCheckCacheKey, Date.now() + '')
            }).catch(console.error)
        })

        return () => {
            PubSub.unsubscribe(refreshListener)
        }
    }, [setUser])
}

export default useRefreshUser