import './index.css'
import { useContext } from 'react'
import AppContext from '../../common/context'

function NewGuyPrize() {
    const { toggleNewGuyDuration, newGuyDuration } = useContext(AppContext)

    const close = () => {
        toggleNewGuyDuration(false)
    }

    return (
        <div className='app-page-container dialog'>
            <div className='app-new-guy-prize-container'>
                <i className='app-new-guy-prize-close' onClick={close}></i>
                <div className='app-new-guy-prize-content'>
                    <p className='app-new-guy-prize-value'>{newGuyDuration}<span className='app-new-guy-prize-value-unit'>分钟</span></p>
                    <button className='app-new-guy-prize-btn' onClick={close}></button>
                </div>
            </div>
        </div>
    )
}

export default NewGuyPrize