export const SlideFromBottom = {
    initial: { y: '100vh' },
    animate: { y: '0vh' },
    exit: { y: '100vh' },
    transition: {
        type: 'tween'
    }
}

export const SlideFromBottomAuto = {
    initial: { y: '100%' },
    animate: { y: '0' },
    exit: { y: '100%' },
    transition: {
        type: 'tween'
    }
}

export const SlideFromRight = {
    initial: { x: '100vw' },
    animate: { x: '0vw' },
    exit: { x: '100vw' },
    transition: {
        type: 'tween'
    }
}

export const Transition = {
    transition: {
        type: 'tween'
    }
}