import './index.css'
import { useContext, useState, useRef, useCallback, useEffect } from 'react'
import AppContext from '../../common/context'
import { motion } from 'framer-motion'
import { SlideFromRight, SlideFromBottom } from '../../animation'
import TrackItem from './trackItem'
import IconItem from './iconItem'
import { getRem } from '../../common/util'

const MaxLevel = 10
const LvArr = (function () {
    const ret = []
    for (let i = 0; i < MaxLevel; ++i) {
        ret.push({ id: i + 1 })
    }
    return ret
})()

const getLvTip = (lv, userLv) => {
    if (lv === userLv) {
        return '成长等级可加快排队速度'
    }
    if (lv < userLv) {
        return '您已超越该等级'
    }
    return '未解锁该等级'
}

function LvDetail() {
    const { sysConfig, user, toggleLvDetail, lvDetailFromVertical } = useContext(AppContext)
    const [currentLv, setCurrentLv] = useState(Math.min(MaxLevel, user.lv))
    const [intLv, setIntLv] = useState(Math.min(MaxLevel, user.lv))
    const [progressStyle, setProgressStyle] = useState('0')
    const [currentMax, setCurrentMax] = useState('')
    const isDrag = useRef(false)
    const touchOrigin = useRef(0)
    const currentLvRef = useRef(Math.min(MaxLevel, user.lv))
    const raf = useRef(null)
    const close = useCallback(() => {
        toggleLvDetail(false)
    }, [toggleLvDetail])
    const touchStart = useCallback(e => {
        isDrag.current = true
        touchOrigin.current = e.touches[0].screenX
        if (raf.current) {
            cancelAnimationFrame(raf.current)
            raf.current = null
        }
    }, [])
    const touchMove = useCallback(e => {
        if (isDrag.current) {
            const delta = touchOrigin.current - e.touches[0].screenX
            let current = currentLvRef.current + delta / (getRem() * 2)
            if (current < 1) {
                current = 1
            } else if (current > MaxLevel) {
                current = MaxLevel
            }
            setCurrentLv(current)
        }
    }, [])

    useEffect(() => {
        setIntLv(Math.round(currentLv))
    }, [currentLv])

    useEffect(() => {
        if (user.lv < intLv) {
            return setProgressStyle('0')
        }
        if (user.lv > intLv) {
            return setProgressStyle('100%')
        }
        const { min, max } = sysConfig.lvs.find(l => l.id === intLv)
        setProgressStyle(`${(user.growth - min) * 100 / (max - min)}%`)
    }, [sysConfig.lvs, intLv, user.growth, user.lv])

    useEffect(() => {
        setCurrentMax(sysConfig.lvs.find(l => l.id === intLv).max + '')
    }, [sysConfig.lvs, intLv])

    const touchEnd = () => {
        isDrag.current = false
        currentLvRef.current = currentLv
        const closest = Math.round(currentLv)
        if (closest !== currentLv) {
            const rise = closest > currentLv
            let step = 0
            const task = () => {
                if (rise) {
                    step += 0.02
                } else {
                    step -= 0.02
                }
                let current = currentLv + step
                if (current === closest || (rise && current > closest) || (!rise && current < closest)) {
                    currentLvRef.current = closest
                    setCurrentLv(closest)
                    raf.current = null
                    return
                }
                currentLvRef.current = current
                setCurrentLv(current)
                raf.current = requestAnimationFrame(task)
            }
            raf.current = requestAnimationFrame(task)
        }
    }

    return (
        <motion.div className='app-page-container zIndex5' {...(lvDetailFromVertical ? SlideFromRight : SlideFromBottom)}>
            <div className='app-page-window'>
                <div className='app-lv-detail-container'>
                    <i className='app-popup-close inverse' onClick={close}></i>
                    <div className='app-lv-track-container'>
                        <div className='app-lv-track-awrapper'>
                            {
                                LvArr.map(item => (
                                    <TrackItem key={item.id} lv={item.id} currentLv={currentLv}></TrackItem>
                                ))
                            }
                        </div>
                    </div>
                    <div className='app-lv-icon-container' onTouchStart={touchStart} onTouchEnd={touchEnd} onTouchMove={touchMove}>
                        <div className='app-lv-icon-wrapper'>
                            {
                                LvArr.map(item => (
                                    <IconItem key={item.id} lv={item.id} currentLv={currentLv}></IconItem>
                                ))
                            }
                        </div>
                    </div>
                    <div className='app-lv-detail-card'>
                        <p className='app-lv-growth-detail'>
                            <span className='app-lv-growth-label'>成长值：</span>
                            {user.growth}
                            <span className='app-lv-growth-max'>/{currentMax}</span>
                        </p>
                        <div className='app-lv-progress-container'>
                            <div className='app-lv-progress-txt prev'>Lv.{intLv}</div>
                            <div className='app-lv-progress-wrapper'>
                                <div className='app-lv-progress-track' style={{ width: progressStyle }}></div>
                                {
                                    intLv === user.lv && <div className='app-lv-progress-thumb' style={{ left: progressStyle }}></div>
                                }
                            </div>
                            <div className='app-lv-progress-txt next'>Lv.{intLv + 1}</div>
                        </div>
                        <p className='app-lv-progress-desc'>
                            <span className='app-lv-progress-desc-content'>{getLvTip(intLv, user.lv)}</span>
                        </p>
                    </div>
                    <div className='app-lv-detail-tip-wrapper'>
                        <div className='app-lv-detail-tip'>
                            <span className='app-lv-detail-tip-value'>100</span>
                            <p className='app-lv-detail-tip-txt'>充值1元</p>
                        </div>
                        <div className='app-lv-detail-tip'>
                            <span className='app-lv-detail-tip-value'>500</span>
                            <p className='app-lv-detail-tip-txt invite'>邀请新用户注册</p>
                        </div>
                        <div className='app-lv-detail-tip'>
                            <span className='app-lv-detail-tip-value'>1</span>
                            <p className='app-lv-detail-tip-txt game'>高峰期游玩每分钟</p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default LvDetail