import './index.css'
import { useState, useEffect, useRef } from 'react'
import PubSub from 'pubsub-js'
import { EventTip, EventType, AuthorityErrorFlag } from '../../common/event'

const TipTime = 3000
const GameTipTime = 5000

function Tip() {
    const [show, toggle] = useState(false)
    const [tipMsg, setTipMsg] = useState('')
    const [tipType, setTipType] = useState('')
    const timer = useRef(null)

    useEffect(() => {
        const listener = PubSub.subscribe(EventTip, (_, { msg, type }) => {
            if (msg === AuthorityErrorFlag) {
                return
            }
            if (timer.current) {
                clearTimeout(timer.current)
            }
            setTipMsg(msg)
            setTipType(type || '')
            toggle(true)
            timer.current = setTimeout(() => {
                toggle(false)
                timer.current = null
            }, type === EventType.Game || type === EventType.GameTime ? GameTipTime : TipTime)
        })

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [])

    if (!show) {
        return null
    }

    if (tipType === EventType.Game || tipType === EventType.GameTime) {
        return (
            <div className='app-page-container tip-landscape'>
                <div className='app-game-tip-container'>
                    {
                        tipType === EventType.GameTime
                            ? <p className='app-game-tip-content time'>剩余时长不足<span className='app-game-tip-value'>{tipMsg}</span>分钟，点击<i className='app-game-tip-icon'></i>按钮及时购买时长</p>
                            : <p className='app-game-tip-content'>{tipMsg}</p>
                    }
                </div>
            </div>
        )
    }

    return <div className='app-page-container tip'>
        <div className={tipType ? 'app-tip-box with-type' : 'app-tip-box'}>
            {
                tipType === EventType.Success && <i className='app-tip-icon success'></i>
            }
            <p className='app-tip-content'>{tipMsg}</p>
        </div>
    </div>
}

export default Tip