import { setToLocalStorage, getFromLocalStorage, safeParse, getWindowSizeForLandscape, getRem } from "../../common/util";
import { CustomOpCacheKey } from "../../config";

export const savePanelJson = (gameId, data) => {
    const { width, height } = getWindowSizeForLandscape()
    setToLocalStorage(CustomOpCacheKey + gameId, JSON.stringify({
        ua: window.navigator.userAgent,
        width,
        height,
        children: data
    }))
}

export const getInitialPanelJson = gameId => {
    const data = safeParse(getFromLocalStorage(CustomOpCacheKey + gameId), null)
    if (!data) {
        return []
    }
    const { width, height } = getWindowSizeForLandscape()
    if (width !== data.width || height !== data.height) {
        const rem = getRem()
        data.children.forEach(btn => {
            let { x, y } = btn.position
            x = x * width / data.width
            y = y * height / data.height
            let { scale, btnType } = btn.props
            let size = btnType === 'joystick' ? 1.32 : 0.334
            btn.position.x = Math.min(width - rem * scale * size, x)
            btn.position.y = Math.min(height - rem * scale * size, y)
        })
        savePanelJson(gameId, data.children)
    }
    return data.children
}
