import { parseQueryString } from './common/util'

export const Timeout = 5000
// export const BaseUrl = 'https://server.ruyungame.com/'
// export const AppKey = '0134938397'
// export const HMAccessKeyId = 'd89243f8578'
export const BaseUrl = 'https://game.clgm666.site/'
export const AppKey = '1599887751'
export const HMAccessKeyId = 'b6f33e23d4b'
export const ImageUrl = BaseUrl + 'uploads'
export const ClientType = 'H5'
export const AppVersion = '1.0.0'
export const DefaultChannel = 'channel_h5'
export const ServiceLink = 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=zaXIWIN5Nm'
const WechatLoginLink = 'https://open.weixin.qq.com/connect/oauth2/authorize'
const WechatAppId = 'wxe73bae3250026555'
export const IsWechatBrowser = window.navigator.userAgent.toLowerCase().indexOf('micromessenger') >= 0
export const QQGroup = '907865700'

export const goToPay = (result, payType) => {
    if (payType === 2) {
        const link = `alipays://platformapi/startapp?saId=10000007&qrcode=${encodeURIComponent(result.prePayTn)}`
        window.location.href = IsWechatBrowser
            ? `https://ulink.alipay.com/?scheme=${encodeURIComponent(link)}`
            : link
    }
    if (payType === 1) {
        window.location.href = parseQueryString(result.prePayTn.substring(result.prePayTn.indexOf('?'))).url
    }
}

export const getWechatLoginUrl = (redirect_uri, state) => {
    return `${WechatLoginLink}?appid=${WechatAppId}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
}

export const AuthCacheKey = '_ack'
export const UICacheKey = '_uick'
export const LastCheckCacheKey = '_lcck'
export const ChannelCacheKey = '_cck'
export const SearchHistoryCacheKey = '_shck'
export const SettingPositionCacheKey = '_spck'
export const NewGuyGiftCacheKey = '_nggck'
export const FreeCardCacheKey = '_fcck'
export const CustomOpCacheKey = '_cock_'
export const GameSettingCacheKey = '_gsck_'