import './index.css'

function Dialog({ okTxt = '确认', cancelTxt = '取消', okFunc, cancelFunc, title, desc = false, className }) {
    return (
        <div className={className ? `app-page-container dialog ${className}` : 'app-page-container dialog'}>
            <div className='app-dialog-box'>
                <p className='app-dialog-title'>{title}</p>
                {
                    desc && <p className='app-dialog-desc'>{desc}</p>
                }
                <div className='app-dialog-btns-line'>
                    <button className='app-dialog-btn cancel' onClick={cancelFunc}>{cancelTxt}</button>
                    <button className='app-dialog-btn' onClick={okFunc}>{okTxt}</button>
                </div>
            </div>
        </div>
    )
}

export default Dialog