export const MouseBtns = [
    {
        "id": 98,
        "name": "mouse-left",
        "txt": "左键",
        "value": 512
    },
    {
        "id": 99,
        "name": "mouse-right",
        "txt": "右键",
        "value": 514
    },
    {
        "id": 100,
        "name": "mouse-center",
        "txt": "中键",
        "value": 513
    },
    {
        "id": 101,
        "name": "mouse-up",
        "txt": "滚轮上",
        "value": -1
    },
    {
        "id": 102,
        "name": "mouse-down",
        "txt": "滚轮下",
        "value": -2
    }
]