import { useNavigate, useLocation } from 'react-router-dom'

function useSafeBack() {
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state || {}
    return function safeBack() {
        navigate(state.from ? -1 : '/')
    }
}

export default useSafeBack