import CustomPanel from '../../components/customPanel'
import { noop } from '../../common/util'

function AdminEditer() {
    return (
        <div className='app-page-container app-play-screen-container' style={{ backgroundColor: '#333' }}>
            <CustomPanel close={noop} mode='server'></CustomPanel>
        </div>
    )
}

export default AdminEditer