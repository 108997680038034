import './index.css'
import '../../components/tip/index.css'
import '../../components/accountDetail/index.css'
import { memo, useContext, useEffect, useState, useRef } from 'react'
import AppContext from '../../common/context'
import PubSub from 'pubsub-js'
import { EventGameOver, EventTip, EventGameStart, EventType, EventQualityList, EventPayEnd, EventNoOperation } from '../../common/event'
import Dialog from '../../components/dialog'
import { getTimeForSec, getFromLocalStorage, setToLocalStorage, getRem, isPortrait, getWindowSizeForLandscape } from '../../common/util'
import { addPlayTime, getGameSetting, saveGameSetting } from './service'
import { initPlayer, startPlayer, stopPlayer, addPlayerTime, getOpMethod, setOpMethod, toggleAudioPlay, toggleVibrate, switchResolution, resetNoInputTimer, setFullKeyboardState } from '../../common/player'
import { motion, useMotionValue } from 'framer-motion'
import { SettingPositionCacheKey } from '../../config'
import CustomPanel from '../../components/customPanel'
import GamePanel from '../../components/customPanel/gamePanel'
// import useOrientation from '../../hooks/useOrientation'

const HiddenInput = 'app-play-debug-hidden'
const Mins5TipTime = 5 * 60
const Mins1TipTime = 60
const Mins0TipTime = 10
const saveSettingPosition = (x, y) => {
    if (isPortrait()) {
        setToLocalStorage(SettingPositionCacheKey, `${y}|${-x}`)
    } else {
        setToLocalStorage(SettingPositionCacheKey, `${x}|${y}`)
    }
}
const calcDragConstraints = () => {
    const { width, height } = getWindowSizeForLandscape()
    const rem = getRem()
    const size = rem * 0.36
    const top = rem * 0.89
    const right = rem * 0.16
    const landscapeConstraints = {
        left: Math.round(size + right - width),
        right: Math.round(right),
        top: Math.round(-top),
        bottom: Math.round(height - top - size)
    }
    if (isPortrait()) {
        return {
            top: landscapeConstraints.left,
            bottom: landscapeConstraints.right,
            left: -landscapeConstraints.bottom,
            right: -landscapeConstraints.top
        }
    }
    return landscapeConstraints
}
const initSettingPosition = () => {
    const cp = getFromLocalStorage(SettingPositionCacheKey)
    const rect = calcDragConstraints()
    if (!cp) {
        return { x: 0, y: 0, rect }
    }
    const arr = cp.split('|')
    let x = Number(arr[0]) || 0
    let y = Number(arr[1]) || 0
    if (isPortrait()) {
        [x, y] = [-y, x]
    }
    let needFix = false
    if (x < rect.left) {
        needFix = true
        x = rect.left
    } else if (x > rect.right) {
        needFix = true
        x = rect.right
    }
    if (y < rect.top) {
        needFix = true
        y = rect.top
    } else if (y > rect.bottom) {
        needFix = true
        y = rect.bottom
    }
    if (needFix) {
        saveSettingPosition(x, y)
    }
    return { x, y, rect }
}
const SettingPosition = initSettingPosition()

function Play() {
    const { showPlayScreen, togglePlayScreen, game, user, sysConfig, setPayIndex, togglePay } = useContext(AppContext)
    const [showSetting, toggleSetting] = useState(false)
    const [showStopTip, toggleStopTip] = useState(false)
    const [playInfo, setPlayInfo] = useState({})
    const [played, setPlayed] = useState('')
    const [playLeft, setPlayLeft] = useState('')
    const [methodIndex, setMethodIndex] = useState(-1)
    const [openAudio, toggleAudio] = useState(false)
    const [openShake, toggleShake] = useState(false)
    const [qualityList, setQualityList] = useState([])
    const [qualityId, setQualityId] = useState(-1)
    const [nitTime, setNitTime] = useState(0)
    const [showNit, toggleNit] = useState(false)
    const [showFreeOver, toggleFreeOver] = useState(false)
    const [dragRect, setDragRect] = useState(SettingPosition.rect)
    const [showCustomEditPanel, toggleCustomEditPanel] = useState(false)
    const [showCustomPanel, toggleCustomPanel] = useState(false)
    const timer = useRef(null)
    const playTimer = useRef(null)
    const addFlag = useRef(false)
    const channelRef = useRef(1)
    const tempTimeRef = useRef(0)
    const x = useMotionValue(SettingPosition.x)
    const y = useMotionValue(SettingPosition.y)

    useEffect(() => {
        if (showPlayScreen) {
            initPlayer()
            const { appChannel, ctoken, pkgName, playTime, protoData, utoken, tempTime, channel, accountData, gameId } = game
            const autoPlayAudio = getGameSetting(gameId, true).autoPlayAudio !== false
            toggleAudio(autoPlayAudio)
            startPlayer(
                'app-play-screen',
                user.uid,
                utoken,
                pkgName,
                appChannel,
                ctoken,
                playTime + '',
                sysConfig.notInputTimeOut,
                protoData,
                accountData,
                autoPlayAudio
            )
            setPlayInfo(info => ({ ...info, playTime }))
            channelRef.current = channel
            tempTimeRef.current = channel && tempTime
        }
    }, [showPlayScreen, game, user.uid, sysConfig.notInputTimeOut])

    useEffect(() => {
        if (showPlayScreen) {
            const fixSettingPosition = () => {
                const { x: fixedX, y: fixedY, rect } = initSettingPosition()
                setDragRect(rect)
                x.set(fixedX)
                y.set(fixedY)
            }
            fixSettingPosition()
            window.addEventListener('resize', fixSettingPosition)

            return () => {
                window.removeEventListener('resize', fixSettingPosition)
            }
        }
    }, [showPlayScreen, x, y])

    useEffect(() => {
        const startListener = PubSub.subscribe(EventGameStart, (_, extraInfo) => {
            const { cid } = extraInfo
            setPlayInfo(info => info.cid === cid ? info : { ...info, cid, startTime: Date.now() })
            const gameSetting = getGameSetting(game.gameId, user.role === 1)
            if (gameSetting.opmethod !== void 0) {
                if (gameSetting.opmethod === 2) {
                    toggleCustomPanel(true)
                }
                setOpMethod(gameSetting.opmethod)
            }
            toggleVibrate(gameSetting.enableVibrate || false)
            toggleShake(gameSetting.enableVibrate || false)
        })
        const qualityListListener = PubSub.subscribe(EventQualityList, (_, extraInfo) => {
            const { selected, list } = extraInfo
            setQualityList(list.slice(0, 3))
            setQualityId(selected)
            const gameSetting = getGameSetting(game.gameId, user.role === 1)
            if (gameSetting.bitrate) {
                const quality = list.find(r => r.bitRate === gameSetting.bitrate)
                if (quality && quality.id !== selected) {
                    setQualityId(quality.id)
                    switchResolution(quality.id)
                }
            }
        })
        const noInputListener = PubSub.subscribe(EventNoOperation, (_, extraInfo) => {
            const { noInputRemainingTime } = extraInfo
            setNitTime(noInputRemainingTime)
            toggleNit(true)
        })

        return () => {
            PubSub.unsubscribe(startListener)
            PubSub.unsubscribe(qualityListListener)
            PubSub.unsubscribe(noInputListener)
        }
    }, [game.gameId, user.role])

    useEffect(() => {
        const gameOver = PubSub.subscribe(EventGameOver, (_, extraInfo) => {
            if (extraInfo.stateChangeReason === '100002') {
                PubSub.publish(EventTip, { msg: '该账号已在其他设备登录' })
            }
            toggleSetting(false)
            toggleStopTip(false)
            setPlayInfo({})
            toggleNit(false)
            toggleFreeOver(false)
            toggleCustomEditPanel(false)
            toggleCustomPanel(false)
            togglePlayScreen(false)
        })

        return () => {
            PubSub.unsubscribe(gameOver)
        }
    }, [togglePlayScreen])

    useEffect(() => {
        if (timer.current) {
            clearInterval(timer.current)
            timer.current = null
        }
        if (showSetting) {
            setMethodIndex(getOpMethod())
            let countDown = Math.round((Date.now() - playInfo.startTime) / 1000)
            let leftCountDown = playInfo.playTime - countDown
            setPlayed(getTimeForSec(countDown))
            setPlayLeft(getTimeForSec(leftCountDown))
            timer.current = setInterval(() => {
                ++countDown
                --leftCountDown
                setPlayed(getTimeForSec(countDown))
                leftCountDown >= 0 && setPlayLeft(getTimeForSec(leftCountDown))
            }, 1000)
        }
    }, [showSetting, playInfo.startTime, playInfo.playTime])

    useEffect(() => {
        if (playTimer.current) {
            clearTimeout(playTimer.current)
            playTimer.current = null
        }
        const startTime = playInfo.startTime
        if (startTime) {
            const playTime = playInfo.playTime
            let mins5Tip = false
            let mins0Tip = false
            let autoChange = false
            let freeOverTip = false
            const check = () => {
                const past = (Date.now() - startTime) / 1000
                const left = playTime - past
                if (tempTimeRef.current && tempTimeRef.current - past <= Mins0TipTime) {
                    tempTimeRef.current = 0
                    PubSub.publish(EventTip, { msg: '免费时段结束，即将消耗高峰时长', type: EventType.Game })
                }
                if (!mins5Tip && left <= Mins5TipTime) {
                    if (!autoChange && channelRef.current === 0) {
                        autoChange = true
                        PubSub.publish(EventPayEnd, { autoCharge: true })
                    } else {
                        mins5Tip = true
                        PubSub.publish(EventTip, { msg: '5', type: EventType.GameTime })
                    }
                }
                if (channelRef.current === 0 && !freeOverTip && left <= Mins1TipTime) {
                    freeOverTip = true
                    toggleFreeOver(true)
                }
                if (!mins0Tip && left <= Mins0TipTime) {
                    mins0Tip = true
                    toggleFreeOver(false)
                    PubSub.publish(EventTip, { msg: '游戏时长已用完，即将退出游戏', type: EventType.Game })
                }
                playTimer.current = setTimeout(check, 2500)
            }
            check()
        }
    }, [playInfo.startTime, playInfo.playTime])

    useEffect(() => {
        if (!showPlayScreen) {
            return
        }

        const listener = PubSub.subscribe(EventPayEnd, (_, params) => {
            if (addFlag.current) {
                return
            }
            addFlag.current = true
            const { gameId, ctoken, utoken } = game
            addPlayTime(playInfo.cid, gameId).then(({ playTime }) => {
                addPlayerTime(playTime, ctoken, user.uid, utoken, () => {
                    channelRef.current = 1
                    if (params && params.autoCharge) {
                        tempTimeRef.current = Math.round(Mins5TipTime + ((Date.now() - playInfo.startTime) / 1000))
                    }
                    setPlayInfo(info => ({ ...info, playTime }))
                    toggleFreeOver(false)
                })
            }).catch(e => {
                if (e.code !== 202) {
                    PubSub.publish(EventTip, { msg: e.message })
                }
            }).finally(() => {
                addFlag.current = false
            })
        })

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [game, playInfo.cid, playInfo.startTime, user.uid, showPlayScreen])

    const openSetting = () => {
        toggleSetting(true)
    }

    const closeSetting = () => {
        toggleSetting(false)
    }

    const stopPropagation = e => {
        e.stopPropagation()
    }

    const showStopDialog = () => {
        closeSetting()
        toggleStopTip(true)
    }

    const closeStopTip = () => {
        toggleStopTip(false)
    }

    const closeNit = () => {
        resetNoInputTimer()
        toggleNit(false)
    }

    const copyDebugCode = () => {
        document.getElementById(HiddenInput).select()
        document.execCommand('copy')
        PubSub.publish(EventTip, { msg: '复制成功', type: EventType.Success })
    }

    const addTimeInGame = () => {
        // PubSub.publish(EventPayEnd)
        setPayIndex(1)
        togglePay(true)
    }

    const changeMethod = n => {
        if (n !== methodIndex || n === 2) {
            if (n === 2) {
                if (user.role !== 1) {
                    return PubSub.publish(EventTip, { msg: '自定义模式为VIP专属功能' })
                }
                resetNoInputTimer()
                closeSetting()
                toggleCustomEditPanel(true)
            } else {
                toggleCustomEditPanel(false)
            }
            toggleCustomPanel(false)
            if (n !== methodIndex) {
                setOpMethod(n)
                setMethodIndex(n)
                saveGameSetting(game.gameId, { opmethod: n })
            }
        }
    }

    const changeQuality = (id, name, bitrate) => {
        if (id !== qualityId) {
            if (Number(bitrate) > 1000 && user.role !== 1) {
                return PubSub.publish(EventTip, { msg: `${name}画质为VIP专属功能` })
            }
            setQualityId(id)
            switchResolution(id)
            saveGameSetting(game.gameId, { bitrate })
        }
    }

    const toggleAudioFunc = () => {
        toggleAudioPlay(!openAudio)
        toggleAudio(!openAudio)
        saveGameSetting(game.gameId, { autoPlayAudio: !openAudio })
    }

    const toggleShakeFunc = () => {
        toggleVibrate(!openShake)
        toggleShake(!openShake)
        saveGameSetting(game.gameId, { enableVibrate: !openShake })
        // PubSub.publish(EventTip, { msg: '暂不支持此功能' })
    }

    const dragEndHandle = () => {
        saveSettingPosition(x.current, y.current)
    }

    if (!showPlayScreen) {
        return null
    }

    const closeCustomEditPanel = () => {
        toggleCustomEditPanel(false)
        toggleCustomPanel(true)
    }

    const getQualityClassName = quality => {
        let className = 'app-setting-op-item'
        if (Number(quality.bitRate) > 1000) {
            className += ' vip'
        }
        if (quality.id === qualityId) {
            className += ' active'
        }
        return className
    }

    const showFullKeyboard = () => {
        setFullKeyboardState(true)
    }

    return (
        <div className='app-page-container app-play-screen-container'>
            <div className='app-play-screen' id='app-play-screen'></div>
            {
                showCustomPanel && <GamePanel gameId={game.gameId} />
            }
            {
                showFreeOver && <div className='app-play-screen-mask readonly'>
                    <div className='app-game-tip-container'>
                        <p className='app-game-tip-content time'>免费时段结束，点击<i className='app-game-tip-icon'></i>按钮及时购买时长</p>
                    </div>
                </div>
            }
            <div className='app-page-container readonly layout zIndex3 nest'>
                <i className='app-play-screen-kb' onClick={showFullKeyboard}></i>
                <motion.i drag dragConstraints={dragRect} dragMomentum={false} dragElastic={false} style={{ x, y }} className={showSetting ? 'app-play-screen-setting hidden' : 'app-play-screen-setting'} onClick={openSetting} onDragTransitionEnd={dragEndHandle}></motion.i>
            </div>
            {
                showSetting && <div className='app-play-screen-mask' onClick={closeSetting}>
                    <div className='app-play-setting-container'>
                        <div className='app-play-setting-content' onClick={stopPropagation}>
                            <div className='app-play-setting-header'>
                                <button className='app-play-setting-btn-stop' onClick={showStopDialog}>下机</button>
                                <div className='app-play-setting-debug'>
                                    <p className='app-play-setting-debug-content'>调试码<i className='app-play-setting-debug-copy' onClick={copyDebugCode}></i></p>
                                    <textarea className={HiddenInput} id={HiddenInput} value={playInfo.cid || ''} readOnly></textarea>
                                </div>
                                <p className='app-play-time-txt'>已玩时长：{played}</p>
                            </div>
                            <div className='app-page-window'>
                                <div className='app-setting-options-content'>
                                    <p className='app-setting-option-title'>控制方法</p>
                                    <div className='app-setting-op-ul'>
                                        <div className={methodIndex === 0 ? 'app-setting-op-item active' : 'app-setting-op-item'} onClick={() => changeMethod(0)}>
                                            <i className='app-setting-op-icon handle'></i>
                                            <p className='app-setting-op-txt'>官方手柄</p>
                                        </div>
                                        <div className={methodIndex === 1 ? 'app-setting-op-item active' : 'app-setting-op-item'} onClick={() => changeMethod(1)}>
                                            <i className='app-setting-op-icon mouse'></i>
                                            <p className='app-setting-op-txt'>官方键鼠</p>
                                        </div>
                                        <div className={methodIndex === 2 ? 'app-setting-op-item active vip' : 'app-setting-op-item vip'} onClick={() => changeMethod(2)}>
                                            <i className='app-setting-op-icon custom'></i>
                                            <p className='app-setting-op-txt'>自定义</p>
                                        </div>
                                    </div>
                                    <p className='app-setting-option-title'>游戏画质</p>
                                    <div className='app-setting-quality-ul'>
                                        {
                                            qualityList.map(item => (
                                                <div className={getQualityClassName(item)} key={item.id} onClick={() => changeQuality(item.id, item.name, item.bitRate)}>
                                                    <p className='app-setting-op-txt'>{item.name}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='app-setting-switch-wrapper audio'>
                                        <span className='app-setting-switch-txt'>{openAudio ? '开' : '关'}</span>
                                        <div className={openAudio ? 'app-setting-switch active' : 'app-setting-switch'} onClick={toggleAudioFunc}>
                                            <motion.div className='app-ad-switch-btn' transition={{ type: 'tween' }} animate={{ x: openAudio ? '80%' : '0%' }}></motion.div>
                                        </div>
                                        <p className='app-setting-option-title'>游戏声音</p>
                                    </div>
                                    <div className='app-setting-switch-wrapper'>
                                        <span className='app-setting-switch-txt'>{openShake ? '开' : '关'}</span>
                                        <div className={openShake ? 'app-setting-switch active' : 'app-setting-switch'} onClick={toggleShakeFunc}>
                                            <motion.div className='app-ad-switch-btn' transition={{ type: 'tween' }} animate={{ x: openShake ? '80%' : '0%' }}></motion.div>
                                        </div>
                                        <p className='app-setting-option-title'>手机震动</p>
                                    </div>
                                </div>
                            </div>
                            <div className='app-play-setting-footer'>
                                <button className='app-play-renew-btn' onClick={addTimeInGame}>补充时长</button>
                                <p className='app-play-toal-time-txt'>可用游戏时长：{playLeft}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showCustomEditPanel && <CustomPanel close={closeCustomEditPanel} gameId={game.gameId}></CustomPanel>
            }
            {
                showStopTip && <Dialog
                    className={'gameover zIndex3 nest'}
                    cancelTxt='继续玩'
                    okTxt='确定'
                    okFunc={stopPlayer}
                    cancelFunc={closeStopTip}
                    title={'是否结束游戏'}></Dialog>
            }
            {
                showNit && <div className='app-play-screen-mask' onClick={closeNit}>
                    <div className='app-game-tip-container'>
                        <p className='app-game-tip-content'>检测到您长时间未操作游戏，{nitTime}秒后将自动退出游戏</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default memo(Play)