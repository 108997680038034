import './index.css'
import '../../pages/report/index.css'
import { addOrEditAccount } from "./service";
import { useState, useRef } from 'react';
import { motion } from 'framer-motion'
import PubSub from 'pubsub-js';
import { EventTip, EventType } from '../../common/event';

function AccountDetail({ callback, gameid, gameName, original }) {
    const [account, setAccount] = useState(original ? original.account : '')
    const [password, setPassword] = useState(original ? original.password : '')
    const [remark, setRemark] = useState(original ? original.remark : gameName)
    const [isDefault, toggleDefault] = useState(original ? original.defaultUSE === 1 : false)
    const submitFlag = useRef(false)

    const inputAccount = e => {
        const { value } = e.target
        setAccount(value)
    }

    const inputPassword = e => {
        const { value } = e.target
        setPassword(value)
    }

    const inputRemark = e => {
        const { value } = e.target
        setRemark(value)
    }

    const cleanAccount = () => {
        setAccount('')
    }

    const cleanPassword = () => {
        setPassword('')
    }

    const cleanRemark = () => {
        setRemark('')
    }

    const toggleDefaultFunc = () => {
        toggleDefault(d => !d)
    }

    const invalid = !(account || '').trim() || !(password || '').trim() || !(remark || '').trim()

    const submit = () => {
        if (invalid || submitFlag.current) {
            return
        }
        submitFlag.current = true
        addOrEditAccount(account, password, remark, gameid, isDefault ? 1 : 0, original ? original.id : null).then(() => {
            PubSub.publish(EventTip, { msg: '绑定成功', type: EventType.Success })
            callback()
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    return (
        <div>
            <p className='app-report-label require first'>账号</p>
            <div className='app-report-input-wrapper'>
                <input className='app-report-input' placeholder='请输入要绑定的账号' value={account} onChange={inputAccount}></input>
                {
                    !!(account || '').trim() && <i className='app-report-input-del' onClick={cleanAccount}></i>
                }
            </div>
            <p className='app-report-label require'>密码</p>
            <div className='app-report-input-wrapper'>
                <input className='app-report-input' type='password' placeholder='请输入密码' value={password} onChange={inputPassword}></input>
                {
                    !!(password || '').trim() && <i className='app-report-input-del' onClick={cleanPassword}></i>
                }
            </div>
            <p className='app-report-label require'>备注</p>
            <div className='app-report-input-wrapper'>
                <input className='app-report-input' placeholder='请输入游戏名称' value={remark} onChange={inputRemark}></input>
                {
                    !!(remark || '').trim() && <i className='app-report-input-del' onClick={cleanRemark}></i>
                }
            </div>
            <div className='app-ad-switch-wrapper'>
                <div className={isDefault ? 'app-ad-switch active' : 'app-ad-switch'} onClick={toggleDefaultFunc}>
                    <motion.div className='app-ad-switch-btn' transition={{ type: 'tween' }} animate={{ x: isDefault ? '80%' : '0%' }}></motion.div>
                </div>
                <p className='app-ad-switch-txt'>设为默认账号</p>
            </div>
            <div className='app-report-submit-btn-wrapper closest'>
                <button className={invalid ? 'app-report-submit-btn disabled' : 'app-report-submit-btn'} onClick={submit}>保存</button>
            </div>
        </div>
    )
}

export default AccountDetail