import './index.css'
import { getAccountList, deleteAccount } from './service'
import { useEffect, useState, useRef } from 'react'
import PubSub from 'pubsub-js'
import { EventTip } from '../../common/event'
import { motion } from 'framer-motion'
import { SlideFromBottomAuto } from '../../animation'
import { showByPassword } from '../../common/util'
import Dialog from '../dialog'
import AccountDetail from '../accountDetail'

function AccountList({ close, gameid, start, refreshBindAccount, index, gameName }) {
    const [list, setList] = useState([])
    const [chosenAccount, setChosenAccount] = useState('')
    const [toDelAccount, setToDelAccount] = useState('')
    const [showDelDialog, toggleDelDialog] = useState(false)
    const [refreshHelper, setRefreshHelper] = useState(0)
    const [pageIndex, setPageIndex] = useState(index)
    const [detail, setDetail] = useState(null)
    const firstLoadFlag = useRef(false)

    useEffect(() => {
        pageIndex === 0 && getAccountList(gameid).then(data => {
            setList(data)
            if (!firstLoadFlag.current) {
                firstLoadFlag.current = true
                const defaultAccount = data.find(a => a.defaultUSE === 1)
                defaultAccount && setChosenAccount(defaultAccount.id)
            }
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        })
    }, [gameid, refreshHelper, pageIndex])

    const delAccount = account => {
        setToDelAccount(account)
        toggleDelDialog(true)
    }

    const closeDelDialog = () => {
        toggleDelDialog(false)
    }

    const doDelAccount = () => {
        closeDelDialog()
        deleteAccount(toDelAccount, gameid).then(() => {
            setRefreshHelper(h => h + 1)
            refreshBindAccount()
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        })
    }

    const startGame = () => {
        start(list.find(a => a.id === chosenAccount))
    }

    const addOrEditCallback = () => {
        refreshBindAccount()
        if (index === 1) {
            close()
        } else {
            setPageIndex(0)
        }
    }

    const addAccount = () => {
        setDetail(null)
        setPageIndex(1)
    }

    const editAccount = d => {
        setDetail(d)
        setPageIndex(1)
    }

    return <div className='app-page-container layout'>
        <motion.div className='app-account-list-container' {...SlideFromBottomAuto}>
            <div className='app-account-list-header'>
                <p className='app-account-list-title'>绑定账号</p>
                {
                    pageIndex === 0 && <button className='app-account-list-create' onClick={addAccount}>绑定新号</button>
                }
                <i className='app-account-list-action close' onClick={close}></i>
                <i className='app-account-list-action service'></i>
            </div>
            {
                pageIndex === 0 && <>
                    <div className='app-account-list-window'>
                        <div className='app-page-window'>
                            {
                                list.map(item => (
                                    <div className={item.id === chosenAccount ? 'app-account-list-item active' : 'app-account-list-item'} key={item.id}>
                                        <p className='app-account-list-item-name'>{item.remark}{item.defaultUSE === 1 && <span className='app-account-list-item-default'>默认</span>}</p>
                                        <p className='app-account-list-item-p account'>账号：{item.account}</p>
                                        <p className='app-account-list-item-p'>密码：{showByPassword(item.password)}</p>
                                        <div className='app-account-list-item-mask' onClick={() => setChosenAccount(item.id)}></div>
                                        <i className='app-account-list-item-op del' onClick={() => delAccount(item.id)}></i>
                                        <i className='app-account-list-item-op edit' onClick={() => editAccount(item)}></i>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button className='app-account-list-start-btn' onClick={startGame}>启动游戏</button>
                </>
            }
            {
                pageIndex === 1 && <div className='app-account-list-detail-window'>
                    <AccountDetail callback={addOrEditCallback} gameid={gameid} gameName={gameName} original={detail}></AccountDetail>
                </div>
            }
        </motion.div>
        {
            showDelDialog && <Dialog title={'确定删除此绑定游戏账号？'} okFunc={doDelAccount} cancelFunc={closeDelDialog}></Dialog>
        }
    </div>
}

export default AccountList