import { useMotionValue, motion, useTransform } from "framer-motion";
import { useEffect } from "react";
import { getRem } from "../../common/util";

function IconItem({ lv, currentLv }) {
    const delta = useMotionValue(lv - currentLv)
    const x = useTransform(delta, latest => {
        latest < -1 && (latest = -1)
        latest > 1 && (latest = 1)
        return 1.3 * getRem() * latest
    })
    const opacity = useTransform(delta, latest => {
        latest = Math.min(1, Math.abs(latest))
        return 1 - latest
    })

    useEffect(() => {
        delta.set(lv - currentLv)
    }, [lv, currentLv, delta])

    return (
        <motion.div className={`app-lv-icon lv-${lv}`} style={{ x, opacity, scale: opacity }}></motion.div>
    )
}

export default IconItem