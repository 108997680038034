import './index.css';
import { Link } from 'react-router-dom'
import { useEffect, useState, useRef, useContext } from 'react'
import AppContext from '../../common/context';
import { getIndexData, getHotNewGuyGift, getFreeCardList } from './service'
import CommonWindow from '../../components/window'
import CustomImage from '../../components/image';
import { register } from 'swiper/element-bundle';
import PubSub from 'pubsub-js';
import { EventTip } from '../../common/event'
import BlockWindow from '../../components/blockWindow'
import Rank from '../../components/rank'
import { motion, AnimatePresence } from 'framer-motion';
import GameRank from '../gameRank';
import { getFromLocalStorage, setToLocalStorage } from '../../common/util'
import { NewGuyGiftCacheKey, FreeCardCacheKey } from '../../config'
import NewGuyGift from '../../components/newGuyGift';

register()

const PopupInterval = 1000 * 60 * 60

function Index({ motionProp, goToStore }) {
  const { sysConfig, user, isLogin, toggleFreeCardEntry, toggleFreeCard, setFreeCardList } = useContext(AppContext)
  const [recommend, setRecommend] = useState([])
  // const [noaccount, setNoaccount] = useState([])
  const [custom, setCustom] = useState([])
  const [more, setMore] = useState([])
  const [showSwiper, toggleSwiper] = useState(false)
  const swiperInstance = useRef(null)
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [showGameRank, toggleRankState] = useState(false)
  const [showNewGuyGift, toggleNewGuyGift] = useState(false)
  const [newGuyGiftInfo, setNewGuyGiftInfo] = useState({})

  useEffect(() => {
    getIndexData().then(data => {
      const {
        recommend: recommendData,
        // noaccount: noaccountData,
        custom: customData,
        more: moreData } = data
      setRecommend(recommendData)
      // setNoaccount(noaccountData)
      setCustom(customData)
      setMore(moreData)
      toggleSwiper(recommendData.length > 0)
    }).catch(e => {
      PubSub.publish(EventTip, { msg: e.message })
    })
  }, [])

  useEffect(() => {
    if (showSwiper && swiperInstance.current) {
      swiperInstance.current.addEventListener('swiperslidechange', e => {
        const [detail] = e.detail
        setSwiperIndex(detail.realIndex)
      })
    }
  }, [showSwiper])

  useEffect(() => {
    if (!isLogin) {
      return
    }
    const now = Date.now()
    if (user.growth === 0 && sysConfig.showNewGiftKit) {
      const lastTime = getFromLocalStorage(NewGuyGiftCacheKey)
      if (!lastTime || now - Number(lastTime) >= PopupInterval) {
        setToLocalStorage(NewGuyGiftCacheKey, now + '')
        getHotNewGuyGift().then(data => {
          if (data) {
            setNewGuyGiftInfo(data)
            toggleNewGuyGift(true)
          }
        }).catch(e => {
          console.error(e)
        })
      }
    }
    if (user.growth > 0 && sysConfig.showTimeCard && now > user.timeCard) {
      const lastTime = getFromLocalStorage(FreeCardCacheKey)
      if (!lastTime || now - Number(lastTime) >= PopupInterval) {
        setToLocalStorage(FreeCardCacheKey, now + '')
        getFreeCardList().then(data => {
          if (data.find(i => i.status === 1)) {
            toggleFreeCardEntry(true)
            setFreeCardList(data)
            toggleFreeCard(true)
          }
        }).catch(e => {
          console.error(e)
        })
      }
    }
  }, [isLogin, user.growth, user.timeCard, sysConfig.showNewGiftKit, sysConfig.showTimeCard, toggleFreeCardEntry, setFreeCardList, toggleFreeCard])

  const changeSwiperIndex = index => {
    swiperInstance.current && swiperInstance.current.swiper.slideToLoop(index)
  }

  const closeNewGuyGift = () => {
    toggleNewGuyGift(false)
  }

  return (
    <>
      <motion.div className='app-page-container' {...motionProp}>
        <div className='app-page-window' id='app-index-window'>
          <div className='app-index-wrapper'>
            <div className='app-index-swiper-wrapper'>
              <div className='app-index-swiper'>
                {
                  showSwiper && <swiper-container ref={swiperInstance} style={{ height: '100%' }} loop="true" autoplay="true" delay="5000">
                    {
                      recommend.map(item => (
                        <swiper-slide key={item.id}>
                          <Link to={`/game/${item.id}`} state={{ from: true }}>
                            <div className='app-index-swiper-item'>
                              <CustomImage className='app-index-swiper-img' src={item.iconh}></CustomImage>
                              <div className='app-index-swiper-item-info'>
                                <p className='app-index-swiper-item-name'>{item.name}</p>
                                <p className='app-index-swiper-item-intro'>{item.introduction}</p>
                              </div>
                            </div>
                          </Link>
                        </swiper-slide>
                      ))
                    }
                  </swiper-container>
                }
              </div>
            </div>
            <div className='app-index-swiper-pagination'>
              <div className='app-index-swiper-pagination-box'>
                {
                  recommend.map((item, n) => (
                    <div className={n === swiperIndex ? 'app-index-swiper-pagination-item active' : 'app-index-swiper-pagination-item'} key={item.id} onClick={() => changeSwiperIndex(n)}>
                      <CustomImage className='app-index-swiper-pagination-img' src={item.iconv}></CustomImage>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              custom.map(c => (
                <div key={c.classify}>
                  <div className='app-index-sub-title'>
                    <span className='app-index-sub-link' onClick={() => goToStore(c.classify)}>查看全部<i className='app-index-link-icon'></i></span>
                    <p className='app-index-sub-title-content'>{c.classifyName}</p>
                  </div>
                  <CommonWindow data={c.data}></CommonWindow>
                </div>
              ))
            }
            {/* <div className='app-index-sub-title'>
              <span className='app-index-sub-link' onClick={() => goToStore(-2)}>查看全部<i className='app-index-link-icon'></i></span>
              <p className='app-index-sub-title-content'>🔥免号专区</p>
            </div>
            <CommonWindow data={noaccount}></CommonWindow> */}
            <div className='app-index-sub-title rank'>
              <span className='app-index-sub-link' onClick={() => toggleRankState(true)}>查看全部<i className='app-index-link-icon'></i></span>
              <p className='app-index-sub-title-content'>游戏榜单</p>
            </div>
            <Rank isGrid={true}></Rank>
            <div className='app-index-sub-title'>
              <p className='app-index-sub-title-content'>更多推荐</p>
            </div>
            <BlockWindow data={more} ready={true}></BlockWindow>
            <p className='app-copyright-info'>长沙濡云互动科技有限公司版权所有</p>
            <p className='app-copyright-info'>湘ICP备2024066791-1 Copyright&copy; 2024</p>
          </div>
        </div>
      </motion.div>
      <AnimatePresence>
        {
          showGameRank && <GameRank toggleRankState={toggleRankState}></GameRank>
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          showNewGuyGift && <NewGuyGift close={closeNewGuyGift} info={newGuyGiftInfo}></NewGuyGift>
        }
      </AnimatePresence>
    </>
  );
}

export default Index;
