import './index.css'
import '../../pages/pay/index.css'
import { useEffect, useState, useRef, useContext } from 'react'
import AppContext from '../../common/context'
import { getAccountProduct, orderAccountProduct } from './service'
import PubSub from 'pubsub-js'
import { EventTip } from '../../common/event'
import { motion } from 'framer-motion'
import { SlideFromBottomAuto } from '../../animation'
import CustomImage from '../image'
import { getHour } from '../../common/util'

function AccountProduct({ close, gameid, refreshBindAccount }) {
    const { setAgreementType, toggleAgreement } = useContext(AppContext)
    const [product, setProduct] = useState(null)
    const [payTypeIndex, setPayTypeIndex] = useState(0)
    const submitFlag = useRef(false)

    useEffect(() => {
        getAccountProduct(gameid).then(data => {
            setProduct(data)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
            close()
        })
    }, [gameid, close])

    const submit = () => {
        if (!product || submitFlag.current) {
            return
        }
        submitFlag.current = true
        orderAccountProduct(product.id, gameid, payTypeIndex === 0 ? 2 : 1).then(data => {
            // TODO
            PubSub.publish(EventTip, { msg: '跳转支付，待完成...' })
            setTimeout(() => {
                refreshBindAccount()
                close()
            }, 3500)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const goToRechargeAgreement = () => {
        setAgreementType(3)
        toggleAgreement(true)
    }

    return (
        <div className='app-page-container layout'>
            <motion.div className='app-account-product-container' {...SlideFromBottomAuto}>
                <div className='app-account-product-header'>
                    <i className='app-account-product-close' onClick={close}></i>
                </div>
                <div className='app-account-product-wrapper'>
                    <div className='app-account-product-content'>
                        <div className='app-account-product-main'>
                            {
                                product && <>
                                    <div className='app-account-product-img-wrapper'>
                                        <CustomImage className='app-account-product-img' src={product.icon}></CustomImage>
                                    </div>
                                    <p className='app-account-product-name'>{product.name}</p>
                                    <div className='app-account-product-items'>
                                        {
                                            product.introduction.split('\n').map((str, n) => (<p key={n}>{str}</p>))
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        <div className='app-account-product-addition'>
                            {
                                product && <p className='app-account-product-addition-txt'>{getHour(product.peakDuration)}永久高峰时长<span className='app-account-product-addition-txt-add'>+</span>{product.vipDays}天会员</p>
                            }
                        </div>
                    </div>
                    {
                        product && <p className='app-account-product-price'><span className='app-account-product-price-original'>原价：&yen;{product.price}</span><span className='app-account-product-price-unit'>&yen;</span>{product.actualPrice}</p>
                    }
                </div>
                <div className='app-pay-types-wrapper index-2'>
                    <div className='app-pay-type' onClick={() => setPayTypeIndex(0)}>
                        <i className={payTypeIndex === 0 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                        <i className='app-pay-type-ali-icon'></i>
                        <p className='app-pay-type-info'><i className='app-pay-type-icon ali'></i>支付宝</p>
                    </div>
                    <div className='app-pay-type' onClick={() => setPayTypeIndex(1)}>
                        <i className={payTypeIndex === 1 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                        <p className='app-pay-type-info'><i className='app-pay-type-icon wechat'></i>微信</p>
                    </div>
                </div>
                <div className='app-account-product-btn-wrapper'>
                    <button className='app-account-product-btn' onClick={submit}>立即购买</button>
                </div>
                <p className='app-account-product-tip'>登录即表示同意<span className='app-account-product-agreement' onClick={goToRechargeAgreement}>《用户充值协议》</span></p>
            </motion.div>
        </div>
    )
}

export default AccountProduct