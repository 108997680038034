import { ImageUrl } from "../../config"

function CustomImage({ className = '', alt = '', internal = false, src }) {
    src = src || ''
    src = internal || src.startsWith('http') || src.startsWith('//') || src.startsWith('data:')
        ? src
        : ImageUrl + src

    return (
        <img alt={alt} className={className} src={src}></img>
    )
}

export default CustomImage