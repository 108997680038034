import './index.css'
import { motion } from 'framer-motion'
import { SlideFromRight } from '../../animation'
import CustomImage from '../../components/image'
import iconx200 from '../../assets/common/icon-200.png'
import { useContext } from 'react'
import AppContext from '../../common/context'

function About({ close }) {
    const { setAgreementType, toggleAgreement } = useContext(AppContext)

    const goToServiceAgreement = () => {
        setAgreementType(1)
        toggleAgreement(true)
    }

    const goToPrivacyAgreement = () => {
        setAgreementType(2)
        toggleAgreement(true)
    }

    return (
        <motion.div className='app-page-container pt44' {...SlideFromRight}>
            <div className='app-page-header'>
                <h1 className='app-page-header-title'>关于我们</h1>
                <i className='app-page-header-back' onClick={close}></i>
            </div>
            <div className='app-page-window'>
                <div className='app-whole-page app-about-container'>
                    <div className='app-about-icon-wrapper'>
                        <CustomImage className='app-about-icon' src={iconx200} internal={true}></CustomImage>
                    </div>
                    <h1 className='app-about-name'>超机云游戏</h1>
                    <p className='app-about-version'>V1.0.0</p>
                    <div className='app-about-agreement-wrapper'>
                        <div className='app-about-agreement' onClick={goToServiceAgreement}>
                            <i className='app-about-to'></i>
                            <p className='app-about-to-name'>用户协议</p>
                        </div>
                        <div className='app-about-agreement' onClick={goToPrivacyAgreement}>
                            <i className='app-about-to'></i>
                            <p className='app-about-to-name'>隐私政策</p>
                        </div>
                    </div>
                    <p className='app-about-company'>长沙濡云互动科技有限公司版权所有</p>
                    <p className='app-about-copyright'>湘ICP备2024066791-1 &copy;2024</p>
                </div>
            </div>
        </motion.div>
    )
}

export default About